import { FormControl, FormGroup, FormHelperText, InputLabel } from "@material-ui/core";
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Measure } from "utils/api";
import { IdMap } from "shared-types";
import styles from "./MeasuresListView.module.scss";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import MeasureItem from "./MeasureItem";

interface Props {
  allMeasures: IdMap<Measure>;
  protocolMeasureIds: string[];
  orderChange?(newOrder: string[]): void;
  handleChange?(event: ChangeEvent<HTMLInputElement>): void;
  error?: string;
}

const MeasuresListView: FC<Props> = ({ allMeasures, protocolMeasureIds, orderChange, handleChange, error }) => {
  const [orderedMeasureIds, setOrderedMeasures] = useState<string[]>([]);

  useEffect(() => {
    setOrderedMeasures([...protocolMeasureIds]);
  }, [protocolMeasureIds]);

  const remainingMeasureIds = Array.from(allMeasures.values())
    .filter((m) => !orderedMeasureIds.includes(m.id))
    .map((m) => m.id);

  const allOrderedMeasures = [...orderedMeasureIds, ...remainingMeasureIds]
    .map((id) => {
      return allMeasures.get(id);
    })
    .filter((m): m is Measure => m !== undefined);

  const moveMeasure = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (fromIndex < orderedMeasureIds.length) {
        const measureIdToMove = orderedMeasureIds[fromIndex];
        const newOrderedMeasureIds = [
          ...orderedMeasureIds.slice(0, fromIndex),
          ...orderedMeasureIds.slice(fromIndex + 1),
        ];
        newOrderedMeasureIds.splice(toIndex, 0, measureIdToMove);
        if (orderChange) {
          orderChange(newOrderedMeasureIds);
        }
        setOrderedMeasures(newOrderedMeasureIds);
      }
    },
    [orderedMeasureIds]
  );

  return (
    <div data-testid="measures-list">
      <InputLabel className={styles.measuresLabel}>Measures</InputLabel>
      <FormControl component="fieldset" fullWidth error={Boolean(error)}>
        <FormGroup>
          <DndProvider backend={HTML5Backend}>
            {allOrderedMeasures.map((measure, index) => (
              <MeasureItem
                key={measure.id}
                measure={measure}
                isChecked={orderedMeasureIds.includes(measure.id)}
                handleChange={handleChange}
                moveMeasure={moveMeasure}
                index={index}
              />
            ))}
          </DndProvider>
        </FormGroup>
        <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default MeasuresListView;
