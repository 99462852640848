import React from "react";
import { Button, Card, CardActions, CardHeader, CardContent, Box } from "@material-ui/core";
import styles from "./AccessDeniedPage.module.scss";

interface AccessDeniedPageProps {
  appName?: string;
  email?: string;
  onLogout: () => void;
}

const AccessDeniedPage: React.FC<AccessDeniedPageProps> = ({ appName, email, onLogout }) => {
  let deniedMessage,
    emailMessage = null;

  if (appName) {
    deniedMessage = <p>You have not been granted access to {appName}.</p>;
  } else {
    deniedMessage = <p>You have not been granted access to this page.</p>;
  }

  if (email) {
    emailMessage = (
      <p>
        Currently logged in as <span className="emph">{email}</span>.
      </p>
    );
  }

  return (
    <Box className={styles.box}>
      <Card className={styles.card} variant="outlined">
        <CardHeader className={styles["card-header"]} title="Access Denied" />
        <CardContent>
          {deniedMessage}
          {emailMessage}
        </CardContent>
        <CardActions>
          <Button size="large" color="inherit" onClick={onLogout}>
            Logout
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default AccessDeniedPage;
