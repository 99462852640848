import { PolyFit } from "models/PolyFit";

export type RegressionMap = Map<string, PolyFit>;

export type Lt1Method = "Calculated" | "Measured";
export type Lt2Method = "D-Max" | "Modified D-Max" | "4mmol";
export type LtMethod = Lt1Method | Lt2Method;

export const isLt1Method = (x: unknown): x is Lt1Method => {
  return x === "Calculated" || x === "Measured";
};

export const isLt2Method = (x: unknown): x is Lt2Method => {
  return x === "D-Max" || x === "Modified D-Max" || x === "4mmol";
};
