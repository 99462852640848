import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { ApiProtocol, getFullAssessment, FullApiAssessment, getFullAssessments, Measure } from "utils/api";
import { IdMap } from "shared-types";
import { useMeasures } from "./useMeasures";
import { useCancelToken } from "./useCancelToken";
import { RegressionMap } from "components/assessment-view/types";
import { PolyFit } from "models/PolyFit";

export interface Protocol extends Omit<ApiProtocol, "measures"> {
  measures: Array<Measure>;
}

export interface FullAssessment extends Omit<FullApiAssessment, "protocol" | "regressions"> {
  protocol: Protocol;
  regressions: RegressionMap;
}

export type FetchedAssessment = [FullAssessment | null, string | null];

const buildRegressions = (assessment: FullApiAssessment) => {
  return (
    assessment &&
    Object.entries(assessment.regressions).reduce((map: RegressionMap, [measureId, regressionData]) => {
      if (regressionData.coefficients !== null && regressionData.rSquared !== null) {
        map.set(measureId, new PolyFit(regressionData.coefficients, regressionData.rSquared));
      }
      return map;
    }, new Map<string, PolyFit>())
  );
};

const hydrateFullAssessment = (fetched: FullApiAssessment, measures: IdMap<Measure>): FullAssessment => {
  const protocolMeasures = fetched.protocol.measures
    .map((measureId) => measures.get(measureId))
    .filter((m): m is Measure => m !== undefined);
  const protocol = {
    ...fetched.protocol,
    measures: protocolMeasures,
  };
  return { ...fetched, protocol, regressions: buildRegressions(fetched) };
};

export function useFullAssessment(assessmentId: string): FetchedAssessment {
  const [measures, measuresError] = useMeasures();

  const [fetchedAssessment, setFetchedAssessment] = useState<FetchedAssessment>([null, null]);

  const onUpdate = useCallback(
    (assessment: FullAssessment | null, error: string | null) => {
      setFetchedAssessment([assessment, error || measuresError]);
    },
    [measuresError, setFetchedAssessment]
  );

  useFullAssessmentEffect(assessmentId, measures, onUpdate);

  return fetchedAssessment;
}

export function useFullAssessmentEffect(
  assessmentId: string,
  measures: IdMap<Measure> | null,
  onUpdate: (assessment: FullAssessment | null, error: string | null) => void
) {
  const cancelToken = useCancelToken();

  useEffect(() => {
    if (assessmentId && measures) {
      // TODO handle errors
      getFullAssessment(assessmentId, cancelToken)
        .then((fetched) => {
          if (!(fetched && measures)) {
            onUpdate(null, null);
          } else {
            onUpdate(hydrateFullAssessment(fetched, measures), null);
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            onUpdate(null, err.toString());
          }
        });
    }
  }, [assessmentId, measures, onUpdate, cancelToken]);
}

export function useFullAssessmentsEffect(
  athleteId: string | null,
  protocolId: string | null,
  measures: IdMap<Measure> | null,
  onUpdate: (assessment: Array<FullAssessment> | null, error: string | null) => void
) {
  const cancelToken = useCancelToken();

  useEffect(() => {
    if (athleteId && protocolId && measures) {
      // TODO handle errors
      getFullAssessments({ athlete: athleteId, protocol: protocolId }, cancelToken)
        .then((fetched) => {
          if (!(fetched && measures)) {
            onUpdate(null, null);
          } else {
            onUpdate(
              fetched.map((a) => hydrateFullAssessment(a, measures)),
              null
            );
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            onUpdate(null, err.toString());
          }
        });
    }
  }, [athleteId, protocolId, measures, onUpdate, cancelToken]);
}
