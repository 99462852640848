import { convertToIdMap } from "utils/misc";
import { IdMap } from "shared-types";
import { ApiProtocol, Measure, Step } from "../../utils/api";

function hydrateProtocolMeasures(protocol: ApiProtocol, measures: IdMap<Measure>) {
  const protocolMeasures = protocol.measures
    .map((measureId) => measures.get(measureId))
    .filter((m): m is Measure => m !== undefined);
  return {
    ...protocol,
    measures: convertToIdMap(protocolMeasures),
  };
}

export function hydrateProtocols(protocols: ApiProtocol[], measures: IdMap<Measure>) {
  return protocols.map((protocol) => hydrateProtocolMeasures(protocol, measures));
}

export function stepHasData(step: Step): boolean {
  return Object.values(step).some((val) => typeof val === "number" || (typeof val === "string" && val !== ""));
}

export function buildEmptyStep(measureIds: string[]): Step {
  return Object.fromEntries(measureIds.map((id) => [id, null]));
}

const getTimeIntervalAsMins = (duration: string): number | undefined => {
  // Django serialized duration has format HH:mm:ss
  if (/^\d\d:\d\d:\d\d$/.test(duration)) {
    const mins = duration.split(":")[1];
    return parseInt(mins);
  }
};

/** Gets blank steps for a given protocol.
 *
 * If numSteps and timeStep are valid this will populate the
 * time values for the test. It will contain a completely empty
 * step at the end to ensure the user has another row to enter data.
 *
 * A valud numSteps is just a number and a valid timeStep has the
 * form HH:mm:ss. This is how django is serializing them by default.
 */
export function getBlankStepsForProtocol(protocol: {
  measures: IdMap<Measure>;
  numSteps: number;
  timeStep: string;
}): Step[] {
  const measureIds = Array.from(protocol.measures.keys());
  const steps: Step[] = [];
  const timeStepMins = getTimeIntervalAsMins(protocol.timeStep);
  let populatedData = false;

  for (let i = 1; i <= protocol.numSteps; i++) {
    const step = buildEmptyStep(measureIds);
    if ("time" in step && timeStepMins) {
      step["time"] = `${timeStepMins * i}`;
      populatedData = true;
    }
    steps.push(step);
  }

  if (populatedData) {
    steps.push(buildEmptyStep(measureIds));
  }

  return steps;
}
