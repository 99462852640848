import React, { FC, useCallback, useMemo, Dispatch } from "react";
import { IdMap } from "shared-types";
import { useAssessmentInterpolation } from "./hooks/useAssessmentInterpolation";
import { showNotification, Notification } from "utils/notifications";
import { RegressionMap } from "./types";
import { AssessmentViewAction, ExplorerInput } from "./hooks/useAVReducer";
import DataTable, { Column } from "components/data-table/DataTable";
import { Tooltip, Typography } from "@material-ui/core";
import styles from "./DataExplorer.module.scss";
import { Info } from "@material-ui/icons";
import { Measure, Step } from "../../utils/api";
import useFormatter from "../../hooks/useFormatter";

interface Props {
  assessmentId: string;
  measures: IdMap<Measure>;
  columns: Column[];
  interpolatedStep: Step | null;
  explorerInput: ExplorerInput;
  regressions: RegressionMap;
  steps: Step[];
  avDispatch: Dispatch<AssessmentViewAction>;
}

const DataExplorer: FC<Props> = ({
  assessmentId,
  measures,
  columns,
  regressions,
  interpolatedStep,
  explorerInput,
  steps,
  avDispatch,
}) => {
  const onInterUpdate = useCallback(
    (interpolations: Record<string, number> | null) => {
      avDispatch({ type: "setinterpolatedStep", interpolations, measures });
    },
    [measures, avDispatch]
  );
  const loading = useAssessmentInterpolation(
    assessmentId,
    explorerInput.id,
    explorerInput.value,
    explorerInput.error,
    onInterUpdate
  );

  const formatter = useFormatter();

  const valueBounds: Record<string, { min: number; max: number }> = useMemo(() => {
    const rtn: Record<string, { min: number; max: number }> = {};

    for (const step of steps) {
      for (const [measure, value] of Object.entries(step)) {
        const valNum = Number(value);
        if (!isNaN(valNum)) {
          if (!rtn[measure]) {
            rtn[measure] = { min: valNum, max: valNum };
          } else {
            rtn[measure].min = rtn[measure].min > valNum ? valNum : rtn[measure].min;
            rtn[measure].max = rtn[measure].max < valNum ? valNum : rtn[measure].max;
          }
        }
      }
    }

    return rtn;
  }, [steps]);

  const getChangedStep = (newSteps: Step[]) => {
    if (interpolatedStep) {
      const newStep = newSteps.length ? newSteps[0] : {};
      for (const [id, value] of Object.entries(interpolatedStep)) {
        const newValue = newStep[id];
        const newNumberValue = Number(newValue);
        const measureBounds = valueBounds[id];
        const error: Notification | null =
          (isNaN(newNumberValue) && { message: "Your value needs to be a number.", severity: "warning" }) ||
          (measureBounds &&
            (newNumberValue < measureBounds.min || newNumberValue > measureBounds.max) && {
              message: `${measures?.get(id)?.label || id} needs to be in the range ${measureBounds.min} to ${
                measureBounds.max
              }.`,
              severity: "warning",
            }) ||
          null;

        // newStep and interpolatedStep only differ by the changed value
        if (value !== newValue) {
          if (error) {
            showNotification(error);
          }
          avDispatch({ type: "setExplorerInput", id, value: newValue, error: Boolean(error) });
        }
      }
    }
  };

  return (
    <div data-testid="data-explorer">
      <div className={styles.heading}>
        <Typography color="primary" variant="h5" className={styles.title}>
          {"Data Explorer"}
        </Typography>
        <Tooltip
          title={
            <div className={styles.infoText}>
              {"Enter data into this table to see interpolated values for the other measures."}
            </div>
          }
          placement="top"
          arrow={true}
        >
          <Info className={styles.infoIcon} />
        </Tooltip>
      </div>
      {interpolatedStep && (
        <DataTable
          columns={columns.map((column) => ({
            ...column,
            editable: Boolean(!loading && regressions.get(column.id)),
          }))}
          rows={[interpolatedStep]}
          pasteEnabled={false}
          sortEnabled={false}
          formatter={formatter}
          onUpdate={getChangedStep}
        />
      )}
    </div>
  );
};

export default DataExplorer;
