import { formatDuration } from "date-fns";
import React, { FC } from "react";
import { IdMap } from "shared-types";
import { ApiProtocol, Measure } from "utils/api";
import { getMeasureLabel } from "utils/misc";
import styles from "./ProtocolListItem.module.scss";

interface Props {
  protocol: ApiProtocol;
  measures: IdMap<Measure>;
  onSelect(): void;
}

const ProtocolListItem: FC<Props> = ({ protocol, measures, onSelect }) => {
  const elements = protocol.timeStep.split(":");
  const humanisedTimeStep = formatDuration(
    {
      hours: parseInt(elements[0]),
      minutes: parseInt(elements[1]),
      seconds: parseInt(elements[2]),
    },
    { format: ["hours", "minutes", "seconds"] }
  );
  return (
    <div className={styles.itemContainer} onClick={onSelect} onKeyDown={onSelect} role="button" tabIndex={0}>
      <div style={{ gridColumn: 1 }} className={styles.field}>
        <div className={styles.fieldHeader}>Name:</div>
        <div>{protocol.name}</div>
      </div>
      <div style={{ gridColumn: 2 }} className={styles.field}>
        <div className={styles.fieldHeader}>Steps:</div>
        <div>
          {protocol.numSteps} {humanisedTimeStep != "" ? "x " + humanisedTimeStep : ""}
        </div>
      </div>
      <div style={{ gridColumn: 3 }} className={styles.field}>
        <div className={styles.fieldHeader}>Controlled Variable:</div>
        <div>{getMeasureLabel(measures.get(protocol.controlled))}</div>
      </div>
    </div>
  );
};

export default ProtocolListItem;
