import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Button,
  Box,
  FormHelperText,
} from "@material-ui/core";
import { useParams, useHistory, Prompt } from "react-router-dom";
import BorderedContainer from "../bordered-container/BorderedContainer";
import AthleteSelect from "./AthleteSelect";
import Throbber from "../throbber/Throbber";
import { FullAssessment, useFullAssessment as useFetchedAssessment } from "hooks/useFullAssessment";
import { useAthletes } from "hooks/useAthletes";
import { useProtocols } from "hooks/useProtocols";
import { useMeasures } from "hooks/useMeasures";
import axios from "axios";
import { postAssessment, putAssessment, ApiAssessment, Step, Unsaved } from "utils/api";
import { useCancelToken } from "hooks/useCancelToken";
import { StepsEditor } from "./steps-editor/StepsEditor";
import { toAusDate } from "utils/misc";
import { buildEmptyStep, getBlankStepsForProtocol, hydrateProtocols, stepHasData } from "./utils";
import { showNotification } from "utils/notifications";
import ErrorPage from "../error-page/ErrorPage";
import TesterSelect from "./TesterSelect";

const createEmptyAssessment = (): Unsaved<ApiAssessment> => {
  return {
    testDate: "",
    temperature: null,
    pressure: null,
    humidity: null,
    steps: [],
    athlete: "",
    protocol: "",
    location: null,
    ergometer: null,
    metabolicCart: null,
    lactateAnalyser: null,
    comments: null,
    tester: null,
    athleteHeight: null,
    athleteWeight: null,
    measures: [],
  };
};

/**
 * This will update the field error state if there is currently an error present for
 * the given field.
 *
 * @param fieldErrors The field errors state
 * @param key The key in field errors to clear
 */
const clearError = (fieldErrors: FieldErrors, key: keyof FieldErrors) => {
  if (key in fieldErrors) {
    const newfieldErrors = { ...fieldErrors };
    delete newfieldErrors[key];
    return newfieldErrors;
  }

  // This will stop react rendering if the state is unchanged.
  return fieldErrors;
};

interface FieldErrors {
  testDate?: string;
  temperature?: string;
  pressure?: string;
  humidity?: string;
  steps?: string;
  athlete?: string;
  protocol?: string;
  athleteHeight?: string;
  athleteWeight?: string;
}

function convertToApiFormat(assessment: FullAssessment): ApiAssessment {
  const allMeasureIds = new Set([...assessment.protocol.measures.map((measure) => measure.id), ...assessment.measures]);
  return {
    ...assessment,
    measures: [...allMeasureIds],
    athlete: assessment.athlete.id,
    tester: assessment.tester?.id || null,
    protocol: assessment.protocol.id,
  };
}

function trimEmptySteps(assessment: Unsaved<ApiAssessment>): Unsaved<ApiAssessment> {
  return {
    ...assessment,
    steps: assessment.steps.filter(stepHasData),
  };
}

function buildFormAssessment(assessment: FullAssessment) {
  const { numSteps: minSteps } = assessment.protocol;
  const formAssessment = convertToApiFormat(assessment);

  formAssessment.steps = Array.from(
    { length: Math.max(minSteps, formAssessment.steps.length + 1) },
    (_, i) => formAssessment.steps[i] || buildEmptyStep(formAssessment.measures)
  );
  return formAssessment;
}

const AssessmentEditView: FC = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const history = useHistory();

  const [measures, measuresError] = useMeasures();
  const [originalAssessment, assessmentError] = useFetchedAssessment(assessmentId);
  const [athletes, athletesError, addAthlete] = useAthletes();
  const { protocols, error: protocolsError } = useProtocols();

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const cancelToken = useCancelToken();

  const hydratedProtocols = useMemo(() => {
    return protocols && measures ? hydrateProtocols(protocols, measures) : [];
  }, [protocols, measures]);

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const [formAssessment, setFormAssessment] = useState<Unsaved<ApiAssessment>>(createEmptyAssessment());

  const setAssessment = useCallback((assessment: Unsaved<ApiAssessment>) => {
    setUnsavedChanges(true);
    setFormAssessment({ ...assessment });
  }, []);

  useEffect(() => {
    if (originalAssessment) {
      setFormAssessment(buildFormAssessment(originalAssessment));
    }
  }, [originalAssessment]);

  if (measuresError || assessmentError || athletesError || protocolsError) {
    return <ErrorPage />;
  }

  if (!(measures && athletes && hydratedProtocols) || (assessmentId && !originalAssessment)) {
    return <Throbber message="Loading..." />;
  }

  const getProtocolSteps = (newProtocolId: string) => {
    const protocol = hydratedProtocols.find((p) => p.id === newProtocolId);
    return protocol ? getBlankStepsForProtocol(protocol) : [];
  };

  const getProtocolMeasures = (newProtocolId: string) => {
    const protocol = protocols?.find((p) => p.id === newProtocolId);
    return protocol ? Array.from(protocol.measures) : [];
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSaving(true);

    let apiCall;

    // TODO validate steps
    if (assessmentId) {
      apiCall = () => putAssessment({ id: assessmentId, ...trimEmptySteps(formAssessment) }, cancelToken);
    } else {
      apiCall = () => postAssessment(trimEmptySteps(formAssessment), cancelToken);
    }

    apiCall()
      .then((savedAssessment) => {
        setSaving(false);
        setUnsavedChanges(false);
        setFieldErrors({});
        history.replace(`/assessments/edit/${savedAssessment.id}`);
        history.push(`/assessments/view/${savedAssessment.id}`);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSaving(false);
          if (err.response?.status === 400 && err.response?.data) {
            setFieldErrors({ ...err.response.data });
          } else {
            showNotification({
              message: "Failed to save assessment, please reload the page and try again.",
              severity: "error",
            });
          }
        }
      });
  };

  const selectedAthlete = athletes?.find((a) => formAssessment.athlete === a.id) || null;

  return (
    <form onSubmit={handleSubmit}>
      <Prompt when={unsavedChanges} message="You have unsaved changes. Are you sure you want to leave?" />
      <Grid container direction="row">
        <Grid item sm={4} xs={12}>
          <Grid container direction="column">
            <BorderedContainer title="Athlete">
              <AthleteSelect
                value={selectedAthlete}
                onChange={(newAtheleteId) => {
                  setFieldErrors((oldState) => clearError(oldState, "athlete"));
                  setAssessment({
                    ...formAssessment,
                    athlete: newAtheleteId,
                  });
                }}
                error={fieldErrors.athlete}
                onAthleteSaved={addAthlete}
                choices={athletes}
              />
              <TextField
                label="Date of Birth"
                type="text"
                fullWidth
                disabled
                value={selectedAthlete ? toAusDate(selectedAthlete.dateOfBirth) : ""}
              />
            </BorderedContainer>
            <BorderedContainer title="Tester">
              <TesterSelect
                selectedTesterId={formAssessment.tester}
                onChange={(newTesterId) => {
                  setAssessment({
                    ...formAssessment,
                    tester: newTesterId,
                  });
                }}
              />
            </BorderedContainer>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <BorderedContainer title="Location Details" data-testid="location-details">
            <TextField
              label="Location"
              inputProps={{ ["data-testid"]: "location-details-location" }}
              fullWidth
              value={formAssessment.location || ""}
              onChange={(event) => {
                setAssessment({
                  ...formAssessment,
                  location: event.target.value,
                });
              }}
            />
            <TextField
              label="Temp (˚C)"
              type="number"
              inputProps={{ step: "0.1", ["data-testid"]: "location-details-temp" }}
              fullWidth
              value={formAssessment.temperature || ""}
              onChange={(event) => {
                setFieldErrors((oldState) => clearError(oldState, "temperature"));
                setAssessment({
                  ...formAssessment,
                  temperature: Number(event.target.value),
                });
              }}
              error={Boolean(fieldErrors.temperature)}
              helperText={fieldErrors.temperature}
            />
            <TextField
              label="Pressure (mmHg)"
              type="number"
              inputProps={{ step: "0.1", ["data-testid"]: "location-details-pressure" }}
              fullWidth
              value={formAssessment.pressure || ""}
              onChange={(event) => {
                setFieldErrors((oldState) => clearError(oldState, "pressure"));
                setAssessment({
                  ...formAssessment,
                  pressure: Number(event.target.value),
                });
              }}
              error={Boolean(fieldErrors.pressure)}
              helperText={fieldErrors.pressure}
            />
            <TextField
              label="Rel. Humidity (%)"
              type="number"
              inputProps={{ step: "0.1", ["data-testid"]: "location-details-humidity" }}
              fullWidth
              value={formAssessment.humidity || ""}
              onChange={(event) => {
                setFieldErrors((oldState) => clearError(oldState, "humidity"));
                setAssessment({
                  ...formAssessment,
                  humidity: Number(event.target.value),
                });
              }}
              error={Boolean(fieldErrors.humidity)}
              helperText={fieldErrors.humidity}
            />
          </BorderedContainer>
        </Grid>
        <Grid item sm={4} xs={12}>
          <BorderedContainer title="Test Details">
            <TextField
              label="Ergometer"
              fullWidth
              value={formAssessment.ergometer || ""}
              onChange={(event) => {
                setAssessment({
                  ...formAssessment,
                  ergometer: event.target.value,
                });
              }}
            />
            <TextField
              label="Metabolic Cart"
              fullWidth
              value={formAssessment.metabolicCart || ""}
              onChange={(event) => {
                setAssessment({
                  ...formAssessment,
                  metabolicCart: event.target.value,
                });
              }}
            />
            <TextField
              label="Lactate Analyser"
              fullWidth
              value={formAssessment.lactateAnalyser || ""}
              onChange={(event) => {
                setAssessment({
                  ...formAssessment,
                  lactateAnalyser: event.target.value,
                });
              }}
            />
            <TextField
              label="Athlete Height (cm)"
              type="number"
              inputProps={{ step: "0.01", ["data-testid"]: "athlete-height" }}
              fullWidth
              value={formAssessment.athleteHeight || ""}
              error={Boolean(fieldErrors.athleteHeight)}
              helperText={fieldErrors.athleteHeight}
              onChange={(event) => {
                setFieldErrors((oldState) => clearError(oldState, "athleteHeight"));
                setAssessment({
                  ...formAssessment,
                  athleteHeight: Number(event.target.value),
                });
              }}
            />
            <TextField
              label="Athlete Weight (kg)"
              type="number"
              inputProps={{ step: "0.01", ["data-testid"]: "athlete-weight" }}
              fullWidth
              value={formAssessment.athleteWeight || ""}
              error={Boolean(fieldErrors.athleteWeight)}
              helperText={fieldErrors.athleteWeight}
              onChange={(event) => {
                setFieldErrors((oldState) => clearError(oldState, "athleteWeight"));
                setAssessment({
                  ...formAssessment,
                  athleteWeight: Number(event.target.value),
                });
              }}
            />
          </BorderedContainer>
        </Grid>
        <Grid item xs={12}>
          <BorderedContainer title="Data">
            <Box m={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="baseline">
              <Box pr={1} width={1 / 3}>
                <TextField
                  label="Date Tested"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ ["data-testid"]: "assessment-date" }}
                  value={formAssessment.testDate || ""}
                  onChange={(event) => {
                    setFieldErrors((oldState) => clearError(oldState, "testDate"));
                    setAssessment({
                      ...formAssessment,
                      testDate: event.target.value,
                    });
                  }}
                  error={Boolean(fieldErrors.testDate)}
                  helperText={fieldErrors.testDate}
                />
              </Box>
              <Box pr={1} width={1 / 3} textAlign="center">
                <FormControl error={Boolean(fieldErrors.protocol)}>
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Protocol
                  </InputLabel>
                  <Select
                    data-testid="protocol-select"
                    value={formAssessment.protocol}
                    onChange={(event) => {
                      const protocolId = event.target.value as string;
                      setFieldErrors((oldState) => clearError(oldState, "protocol"));
                      setAssessment({
                        ...formAssessment,
                        protocol: protocolId,
                        measures: getProtocolMeasures(protocolId),
                        steps: getProtocolSteps(protocolId),
                      });
                    }}
                  >
                    {protocols?.map((protocol) => (
                      <MenuItem key={protocol.name} value={protocol.id}>
                        {protocol.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldErrors.protocol}</FormHelperText>
                </FormControl>
              </Box>
              <Box width={1 / 3}>
                <TextField
                  fullWidth
                  label="Test Comments"
                  value={formAssessment.comments || ""}
                  onChange={(event) => {
                    setAssessment({
                      ...formAssessment,
                      comments: event.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
            <Box m={1}>
              {formAssessment && formAssessment.steps && formAssessment.protocol && (
                <StepsEditor
                  steps={formAssessment.steps}
                  measures={new Set(formAssessment.measures)}
                  requiredMeasures={new Set(protocols?.find((p) => p.id === formAssessment.protocol)?.measures)}
                  onChange={(newSteps: Array<Step>, newMeasures?: Array<string>) => {
                    setFieldErrors((oldState) => clearError(oldState, "steps"));
                    setAssessment({
                      ...formAssessment,
                      measures: newMeasures || formAssessment.measures,
                      steps: newSteps,
                    });
                  }}
                />
              )}
            </Box>
            <Grid item xs={12}>
              <Box m={1}>
                <Button
                  id="test-save"
                  data-testid="test-save"
                  type="submit"
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={saving}
                >
                  Save Test
                </Button>
              </Box>
            </Grid>
          </BorderedContainer>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssessmentEditView;
