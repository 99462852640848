import { IdMap } from "shared-types";
import { Lt1Method, Lt2Method, RegressionMap } from "../types";
import { useReducer, Reducer } from "react";
import { PolyFit } from "models/PolyFit";
import { Annotation } from "components/graph-view/types";
import { FullAssessment } from "hooks/useFullAssessment";
import plotStyles from "../plotStyles";
import { toAusDate } from "utils/misc";
import { Measure, Step } from "../../../utils/api";
import { formatWithUnits } from "../../../hooks/useFormatter";

type GraphTab = "lactate" | "multiple" | "longitudinal";

export interface ExplorerInput {
  id: string | null;
  value: string | null;
  error: boolean;
}

export interface AssessmentViewState {
  assessment: {
    data: FullAssessment | null;
    error: string | null;
  };
  selectedComparisonIds: Array<string>;
  comparisonData: {
    ids: Array<string> | null;
    assessments: Record<string, FullAssessment> | null;
    error: string | null;
  };
  workMeasure: Measure | null;
  annotations: {
    main: Annotation[];
    comparisons: Annotation[];
  };
  interpolatedStep: Step | null;
  explorerInput: ExplorerInput;
  graphTab: GraphTab;
  lt1Method: Lt1Method;
  lt1Value: number | null;
  lt2Method: Lt2Method;
  lt2Value: number | null;
}

export type AssessmentViewAction =
  | { type: "setAssessment"; assessment: FullAssessment | null; error: string | null; measures: IdMap<Measure> | null }
  | { type: "changeGraphTab"; graphTab: GraphTab }
  | { type: "setinterpolatedStep"; interpolations: Record<string, number> | null; measures: IdMap<Measure> | null }
  | { type: "setExplorerInput"; id: string | null; value: string | null; error: boolean }
  | { type: "changeLt1Method"; lt1Method: Lt1Method; measures: IdMap<Measure> | null }
  | { type: "changeLt2Method"; lt2Method: Lt2Method; measures: IdMap<Measure> | null }
  | { type: "setSelectedComparisonIds"; ids: Array<string>; measures: IdMap<Measure> | null }
  | {
      type: "setComparisonData";
      assessments: Array<FullAssessment> | null;
      error: string | null;
    };

const initialState: AssessmentViewState = {
  assessment: {
    data: null,
    error: null,
  },
  selectedComparisonIds: [],
  comparisonData: {
    ids: null,
    assessments: null,
    error: null,
  },
  workMeasure: null,
  annotations: {
    main: [],
    comparisons: [],
  },
  interpolatedStep: null,
  explorerInput: {
    id: null,
    value: null,
    error: false,
  },
  graphTab: "lactate",
  lt1Method: "Calculated",
  lt1Value: null,
  lt2Method: "Modified D-Max",
  lt2Value: null,
};

const getWorkMeasure = (assessment: AssessmentViewState["assessment"]["data"], measures: IdMap<Measure> | null) =>
  (assessment && measures?.get(assessment.protocol.controlled)) || null;

const getLt1Value = (
  assessment: AssessmentViewState["assessment"]["data"],
  lt1Method: AssessmentViewState["lt1Method"]
): AssessmentViewState["lt1Value"] => {
  switch (lt1Method) {
    case "Calculated":
      return assessment?.lt1Calculated || null;
    default:
      return assessment?.lt1Measured || null;
  }
};

const getLt2Value = (
  assessment: AssessmentViewState["assessment"]["data"],
  lt2Method: AssessmentViewState["lt2Method"]
): AssessmentViewState["lt2Value"] => {
  switch (lt2Method) {
    case "4mmol":
      return assessment?.lt2At4mmol || null;
    case "D-Max":
      return assessment?.lt2Dmax || null;
    default:
      return assessment?.lt2DmaxMod || null;
  }
};

const buildAnnotationsForAssessment = (
  lt1: number | null,
  lt2: number | null,
  regressions: RegressionMap | null,
  measures: IdMap<Measure> | null,
  interpolatedStepControlValue: string | null,
  color: string,
  date?: string
): Annotation[] => {
  if (!regressions || !measures) {
    return [];
  }

  const buildHoverString = (lt: number) => ([measureId, regression]: [string, PolyFit]) => {
    const value = regression.getAt(lt);
    const measure = measures.get(measureId);
    if (measure && value) {
      return `${measure.label}: ${formatWithUnits(measure, value.toString())}`;
    } else {
      return "";
    }
  };

  const annotations: Array<Annotation> = [];

  const addAnnotation = (value: number, name: string) => {
    let hoverText = "";
    if (date) {
      hoverText += `Date: ${toAusDate(date)}`;
    }

    for (const regression of regressions.entries()) {
      const regressionText = buildHoverString(value)(regression);
      if (regressionText) {
        hoverText += `${hoverText ? "<br />" : ""}${regressionText}`;
      }
    }

    annotations.push({
      name,
      value,
      color,
      hoverText: hoverText,
    });
  };

  if (lt1 !== null) {
    addAnnotation(lt1, "LT1");
  }

  if (lt2 !== null) {
    addAnnotation(lt2, "LT2");
  }

  if (interpolatedStepControlValue !== null) {
    addAnnotation(Number.parseFloat(interpolatedStepControlValue), "DE");
  }

  return annotations;
};

const buildAnnotationsForComparisonData = (
  comparisonData: AssessmentViewState["comparisonData"],
  selectedComparisonIds: AssessmentViewState["selectedComparisonIds"],
  lt1Method: Lt1Method,
  lt2Method: Lt2Method,
  measures: IdMap<Measure> | null
) => {
  return selectedComparisonIds.flatMap((id, index) => {
    const comparison = comparisonData.assessments?.[id];

    if (comparison) {
      return buildAnnotationsForAssessment(
        getLt1Value(comparison, lt1Method),
        getLt2Value(comparison, lt2Method),
        comparison.regressions,
        measures,
        null,
        plotStyles[(index + 1) % plotStyles.length].dark, // 0 is used for the main graph
        selectedComparisonIds.length ? comparison.testDate : undefined
      );
    }

    return [];
  });
};

const buildInterpolatedStep = (
  assessment: AssessmentViewState["assessment"]["data"],
  explorerInput: AssessmentViewState["explorerInput"],
  interpolations?: Record<string, number>
) => {
  const step: Step = {};
  for (const measure of assessment?.measures || []) {
    if (interpolations?.[measure] !== undefined) {
      step[measure] = interpolations[measure].toString();
    } else if (measure === explorerInput.id) {
      step[measure] = explorerInput.value;
    } else {
      step[measure] = null;
    }
  }
  return step;
};

const avReducer: Reducer<AssessmentViewState, AssessmentViewAction> = (state, action) => {
  switch (action.type) {
    case "setAssessment": {
      const assessmentData = action.assessment;
      const lt1Value = getLt1Value(assessmentData, state.lt1Method);
      const lt2Value = getLt2Value(assessmentData, state.lt2Method);
      const workMeasure = getWorkMeasure(assessmentData, action.measures);
      return {
        ...state,
        assessment: {
          data: assessmentData,
          error: action.error || null,
        },
        workMeasure,
        interpolatedStep:
          assessmentData?.measures.reduce((step, measure) => {
            step[measure] = null;
            return step;
          }, {} as Step) || null,
        lt1Value,
        lt2Value,
        selectedComparisonIds: [],
        annotations: {
          main: buildAnnotationsForAssessment(
            lt1Value,
            lt2Value,
            assessmentData?.regressions || null,
            action.measures,
            (workMeasure?.id && state.interpolatedStep?.[workMeasure.id]) || null,
            plotStyles[0].dark
          ),
          comparisons: [],
        },
      };
    }
    case "changeGraphTab": {
      return {
        ...state,
        graphTab: action.graphTab,
      };
    }
    case "setinterpolatedStep": {
      const interpolatedStep = buildInterpolatedStep(
        state.assessment.data,
        state.explorerInput,
        action.interpolations || undefined
      );
      return {
        ...state,
        interpolatedStep,
        explorerInput: { id: null, value: null, error: false },
        annotations: {
          main: buildAnnotationsForAssessment(
            state.lt1Value,
            state.lt2Value,
            state.assessment.data?.regressions || null,
            action.measures,
            (state.workMeasure?.id && interpolatedStep?.[state.workMeasure.id]) || null,
            plotStyles[0].dark,
            state.selectedComparisonIds.length ? state.assessment.data?.testDate : undefined
          ),
          comparisons: state.annotations.comparisons,
        },
      };
    }
    case "setExplorerInput": {
      const explorerInput = { id: action.id, value: action.value, error: action.error };
      return {
        ...state,
        interpolatedStep: buildInterpolatedStep(state.assessment.data, explorerInput),
        explorerInput: explorerInput,
      };
    }
    case "changeLt1Method": {
      const lt1Value = getLt1Value(state.assessment.data, action.lt1Method);
      return {
        ...state,
        lt1Method: action.lt1Method,
        lt1Value,
        annotations: {
          main: buildAnnotationsForAssessment(
            lt1Value,
            state.lt2Value,
            state.assessment.data?.regressions || null,
            action.measures,
            (state.workMeasure?.id && state.interpolatedStep?.[state.workMeasure.id]) || null,
            plotStyles[0].dark,
            state.selectedComparisonIds.length ? state.assessment.data?.testDate : undefined
          ),
          comparisons: buildAnnotationsForComparisonData(
            state.comparisonData,
            state.selectedComparisonIds,
            action.lt1Method,
            state.lt2Method,
            action.measures
          ),
        },
      };
    }
    case "changeLt2Method": {
      const lt2Value = getLt2Value(state.assessment.data, action.lt2Method);
      return {
        ...state,
        lt2Method: action.lt2Method,
        lt2Value,
        annotations: {
          main: buildAnnotationsForAssessment(
            state.lt1Value,
            lt2Value,
            state.assessment.data?.regressions || null,
            action.measures,
            (state.workMeasure?.id && state.interpolatedStep?.[state.workMeasure.id]) || null,
            plotStyles[0].dark,
            state.selectedComparisonIds.length ? state.assessment.data?.testDate : undefined
          ),
          comparisons: buildAnnotationsForComparisonData(
            state.comparisonData,
            state.selectedComparisonIds,
            state.lt1Method,
            action.lt2Method,
            action.measures
          ),
        },
      };
    }
    case "setSelectedComparisonIds": {
      return {
        ...state,
        selectedComparisonIds: action.ids,
        annotations: {
          main: buildAnnotationsForAssessment(
            state.lt1Value,
            state.lt2Value,
            state.assessment.data?.regressions || null,
            action.measures,
            (state.workMeasure?.id && state.interpolatedStep?.[state.workMeasure.id]) || null,
            plotStyles[0].dark,
            action.ids.length ? state.assessment.data?.testDate : undefined
          ),
          comparisons: buildAnnotationsForComparisonData(
            state.comparisonData,
            action.ids,
            state.lt1Method,
            state.lt2Method,
            action.measures
          ),
        },
      };
    }
    case "setComparisonData": {
      let assessments: Record<string, FullAssessment> | null = null;
      let ids: Array<string> | null = null;

      if (action.assessments) {
        assessments = action.assessments.reduce(
          (acc, a) => ({
            ...acc,
            [a.id]: a,
          }),
          {}
        );
        ids = action.assessments.map((a) => a.id);
      }

      return {
        ...state,
        comparisonData: {
          ids,
          assessments,
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};

export const useAVReducer = () => {
  return useReducer(avReducer, initialState);
};
