import React, { useState, useEffect, FC, useRef, useCallback } from "react";
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";

import { notifications$, Notification } from "utils/notifications";
import useClickOutside from "hooks/useClickOutside";
import styles from "./NotificationsView.module.scss";

const NotificationsView: FC = () => {
  const [currentNotifications, setCurrentNotifications] = useState([] as Notification[]);
  const ref = useRef(null);

  const onClickOutside = useCallback(() => setCurrentNotifications([]), [setCurrentNotifications]);
  useClickOutside(ref, onClickOutside);

  useEffect(() => {
    notifications$.subscribe((notification: Notification): void => {
      setCurrentNotifications((oldState) => _.uniqBy([...oldState, notification], (n) => n.message));
    });

    return (): void => notifications$.unsubscribe();
  }, []);

  return (
    <div data-testid="notifications-view" ref={ref} className={styles.container}>
      {currentNotifications.map((notification) => (
        <Alert
          key={notification.message}
          className={styles.alert}
          elevation={6}
          variant="filled"
          severity={notification.severity}
          onClose={(): void => {
            setCurrentNotifications(currentNotifications.filter((note) => note.message !== notification.message));
          }}
        >
          {notification.message}
        </Alert>
      ))}
    </div>
  );
};

export default React.memo(NotificationsView);
