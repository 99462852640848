import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import ErrorPage from "components/error-page/ErrorPage";
import Throbber from "components/throbber/Throbber";
import { useMeasures } from "hooks/useMeasures";
import React, { FC, useEffect, useState } from "react";
import { ApiProtocol, ApiProtocolError, Unsaved } from "utils/api";
import { getMeasureLabel } from "utils/misc";
import MeasuresListView from "../measures-list/MeasuresListView";
import styles from "./ProtocolEditView.module.scss";

interface Props {
  /** The protocol to edit. */
  protocol: ApiProtocol | null;
  /** Whether data is loading. Effects whether more changes can be made. */
  loading: boolean;
  /** An object of protocol field id's to error messages. Displayed in the form. */
  fieldErrors: ApiProtocolError;
  /** A function to add new protocols. */
  addProtocol(protocol: Unsaved<ApiProtocol>): void;
  /** A function to update existing protocols. */
  updateProtocol(protocol: ApiProtocol): void;
}

const emptyUnsavedProtocol = {
  name: "",
  timeStep: "",
  numSteps: 0,
  controlled: "",
  measures: [],
};

/** A form to edit the list of protocols used within the app. */
const ProtocolEditView: FC<Props> = ({ protocol, loading, fieldErrors, addProtocol, updateProtocol }) => {
  const [measures, measuresError] = useMeasures();
  const [formProtocol, setFormProtocol] = useState<Unsaved<ApiProtocol> | ApiProtocol>(emptyUnsavedProtocol);
  const [visibleFieldErrors, setVisibleFieldErrors] = useState<ApiProtocolError>({});

  useEffect(() => {
    if (protocol) {
      setFormProtocol({ ...protocol });
    } else {
      setFormProtocol(emptyUnsavedProtocol);
    }
  }, [protocol]);

  useEffect(() => {
    setVisibleFieldErrors({ ...fieldErrors });
  }, [fieldErrors]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if ("id" in formProtocol) {
      updateProtocol(formProtocol);
    } else {
      addProtocol(formProtocol);
    }
  };

  if (measuresError || !formProtocol) {
    return <ErrorPage />;
  }

  if (!measures) {
    return <Throbber message="Loading..." />;
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          inputProps={{ ["data-testid"]: "protocol-edit-name" }}
          helperText={visibleFieldErrors.name}
          error={Boolean(visibleFieldErrors.name)}
          value={formProtocol.name}
          className={styles.input}
          fullWidth
          onChange={(event) => {
            const { name: _, ...updatedFieldErrors } = fieldErrors;
            setVisibleFieldErrors(updatedFieldErrors);
            setFormProtocol({
              ...formProtocol,
              name: event.target.value,
            });
          }}
        />
        <TextField
          label="Time step"
          inputProps={{ ["data-testid"]: "protocol-edit-timestep" }}
          helperText={fieldErrors.timeStep || "hh:mm:ss"}
          error={Boolean(visibleFieldErrors.timeStep)}
          value={formProtocol.timeStep}
          className={styles.input}
          fullWidth
          onChange={(event) => {
            const { timeStep: _, ...updatedFieldErrors } = fieldErrors;
            setVisibleFieldErrors(updatedFieldErrors);
            setFormProtocol({
              ...formProtocol,
              timeStep: event.target.value,
            });
          }}
        />
        <TextField
          type="number"
          label="Number of steps"
          inputProps={{ ["data-testid"]: "protocol-edit-num-steps" }}
          helperText={fieldErrors.numSteps}
          error={Boolean(visibleFieldErrors.numSteps)}
          value={formProtocol.numSteps}
          className={styles.input}
          fullWidth
          onChange={(event) => {
            const { numSteps: _, ...updatedFieldErrors } = fieldErrors;
            setVisibleFieldErrors(updatedFieldErrors);
            setFormProtocol({
              ...formProtocol,
              numSteps: Number(event.target.value),
            });
          }}
        />
        <FormControl error={Boolean(fieldErrors.controlled)} fullWidth className={styles.input}>
          <InputLabel id="demo-simple-select-placeholder-label-label" shrink>
            Controled Measure
          </InputLabel>
          <Select
            value={formProtocol.controlled}
            data-testid="protocol-edit-controlled"
            onChange={(event) => {
              const measureId = event.target.value as string;
              const { controlled: _, ...updatedFieldErrors } = fieldErrors;
              setVisibleFieldErrors(updatedFieldErrors);
              setFormProtocol({
                ...formProtocol,
                controlled: measureId,
              });
            }}
          >
            {Array.from(measures.values()).map((measure) => (
              <MenuItem key={getMeasureLabel(measure)} value={measure.id}>
                {getMeasureLabel(measure)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={Boolean(fieldErrors.controlled)}>{fieldErrors.controlled}</FormHelperText>
        </FormControl>
        <MeasuresListView
          allMeasures={measures}
          protocolMeasureIds={formProtocol.measures}
          error={fieldErrors.measures}
          orderChange={(newMeasuresOrder) => {
            setFormProtocol({
              ...formProtocol,
              measures: [...newMeasuresOrder],
            });
          }}
          handleChange={(event) => {
            const { measures: _, ...updatedFieldErrors } = fieldErrors;
            setVisibleFieldErrors(updatedFieldErrors);
            if (event.target.checked) {
              setFormProtocol({
                ...formProtocol,
                measures: [...formProtocol.measures, event.target.name],
              });
            } else {
              setFormProtocol({
                ...formProtocol,
                measures: formProtocol.measures.filter((measureId) => measureId !== event.target.name),
              });
            }
          }}
        />
        <Button
          id="protocol-save"
          type="submit"
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
          fullWidth
        >
          {"id" in formProtocol ? "Update Protocol" : "Save Protocol"}
        </Button>
      </form>
    </div>
  );
};

export default ProtocolEditView;
