import { useCallback } from "react";
import { useMeasures } from "./useMeasures";
import { Measure } from "../utils/api";

const numberRegex = /^[+-]?\d+(\.\d+)?$/;

const containsNumber = (value: string | null | undefined): value is string => Boolean(value && numberRegex.test(value));

const formatWithDecimals = (value: string, decimalPoints: number) => {
  const num = parseFloat(value);
  return num.toFixed(decimalPoints);
};

export const formatWithUnits = (measure?: Measure, value?: string | null) => {
  const unitsSuffix = measure?.units ? ` ${measure.units}` : "";
  const valueString = format(measure, value);
  return `${valueString}${unitsSuffix}`;
};

export const format = (measure?: Measure, value?: string | null) =>
  containsNumber(value) && measure ? formatWithDecimals(value, measure.decimalPlaces) : value;

const useFormatter = () => {
  const [measures] = useMeasures();

  return useCallback(
    (measureId?: string, value?: string | null) => {
      const measure = measureId ? measures?.get(measureId) : undefined;
      return format(measure, value);
    },
    [measures]
  );
};

export default useFormatter;
