export class PolyFit {
  readonly coefficients: Array<number>;
  readonly rSquared: number;

  constructor(coefficients: Array<number>, rSquared: number) {
    this.coefficients = coefficients;
    this.rSquared = rSquared;
  }

  getAt(x: number) {
    return this.coefficients.reduce((total, coef, i) => total + coef * x ** i, 0);
  }
}
