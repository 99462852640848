import React, { useCallback } from "react";
import { Grid, IconButton } from "@material-ui/core";
import MaterialTable, { Column, Query } from "material-table";
import AssessmentOutlined from "@material-ui/icons/AssessmentOutlined";
import EditOutlined from "@material-ui/icons/EditOutlined";

import { NavLink } from "react-router-dom";
import { AssessmentItem } from "./hooks/useAssessmentList";
import { useProtocols } from "hooks/useProtocols";
import { useAthletes } from "hooks/useAthletes";
import Throbber from "../throbber/Throbber";
import { toAusDate } from "utils/misc";
import ErrorPage from "../error-page/ErrorPage";
import { useMeasures } from "hooks/useMeasures";
import { CompactApiAssessment, getCompactAssessments } from "../../utils/api";
import { useCancelToken } from "../../hooks/useCancelToken";
import { buildOrderingParam } from "./assessmentListUtils";
import useFormatter from "../../hooks/useFormatter";

const AssessmentListView: React.FunctionComponent = () => {
  const cancelToken = useCancelToken();
  const { protocols, error: protocolsError } = useProtocols();
  const [athletes, athletesError] = useAthletes();
  const [measures, measuresError] = useMeasures();
  const formatter = useFormatter();

  const fetchPage = useCallback(
    async ({ search, orderBy, orderDirection, page, pageSize }: Query<CompactApiAssessment>) => {
      const limit = pageSize;
      const offset = page * pageSize;
      const ordering = buildOrderingParam(orderBy, orderDirection);
      const assessmentsPage = await getCompactAssessments({ search, offset, limit, ordering }, cancelToken);
      return {
        data: assessmentsPage.results,
        page,
        totalCount: assessmentsPage.count,
      };
    },
    [cancelToken]
  );

  if (protocolsError || athletesError || measuresError) {
    return <ErrorPage />;
  }

  if (!(protocols && athletes && measures)) {
    return <Throbber message="Loading..." />;
  }

  const athleteLookup: { [key: string]: string } = {};
  Array.from(athletes.values()).forEach((at) => {
    athleteLookup[at.id] = `${at.surname}, ${at.firstName} `;
  });

  const protocolLookup: { [key: string]: string } = {};
  protocols.forEach((p) => {
    protocolLookup[p.id] = p.name;
  });

  const numberRenderer = (num: number | null, protocolId: string) => {
    if (!num) {
      return "—";
    }

    const controlledId = protocols.find((p) => p.id === protocolId)?.controlled;
    const measure = controlledId && measures.get(controlledId);
    const units = measure && measure.units;

    const unitsSuffix = units ? ` ${units}` : "";
    const valueString = formatter(controlledId, num.toString());

    return `${valueString}${unitsSuffix}`;
  };

  const columns: Column<CompactApiAssessment>[] = [
    {
      title: "View",
      field: "id",
      sorting: false,
      render: (rowData: AssessmentItem) => {
        return (
          <NavLink data-testid="assessment-list-view-button" className="link" to={`/assessments/view/${rowData.id}`}>
            <IconButton color="primary">
              <AssessmentOutlined />
            </IconButton>
          </NavLink>
        );
      },
    },
    {
      title: "Athlete Name",
      field: "athlete",
      lookup: athleteLookup,
    },
    {
      title: "Test Date",
      field: "testDate",
      render: (a: AssessmentItem) => toAusDate(a.testDate),
      defaultSort: "desc",
    },
    {
      title: "LT1",
      field: "lt1Measured",
      render: (rowData: AssessmentItem) => {
        return numberRenderer(rowData.lt1Measured, rowData.protocol);
      },
    },
    {
      title: "LT2",
      field: "lt2DmaxMod",
      render: (rowData: AssessmentItem) => {
        return numberRenderer(rowData.lt2DmaxMod, rowData.protocol);
      },
    },
    {
      title: "Max",
      field: "maxControlled",
      sorting: false,
      render: (rowData: AssessmentItem) => {
        return numberRenderer(rowData.maxControlled, rowData.protocol);
      },
    },
    { title: "Protocol", field: "protocol", lookup: protocolLookup },
    {
      title: "Edit",
      field: "id",
      sorting: false,
      render: (rowData: AssessmentItem) => (
        <NavLink className="link" to={`/assessments/edit/${rowData.id}`} key={rowData.id}>
          <IconButton color="primary">
            <EditOutlined />
          </IconButton>
        </NavLink>
      ),
    },
  ];

  return (
    <Grid>
      <MaterialTable
        columns={columns}
        data={fetchPage}
        title=""
        options={{
          headerStyle: {
            fontWeight: 600,
            fontSize: "120%",
          },
          pageSize: 10,
          debounceInterval: 500,
        }}
      />
    </Grid>
  );
};

export default AssessmentListView;
