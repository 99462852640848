import React, { useState } from "react";
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { Athlete, postAthlete, Unsaved } from "utils/api";
import { useCancelToken } from "hooks/useCancelToken";
import { showNotification } from "utils/notifications";

interface AthleteSelectProps {
  error?: string;
  value: Athlete | null;
  choices: Athlete[] | null;
  onAthleteSaved: (athlete: Athlete) => void;
  onChange: (athleteId: string) => void;
}

interface FieldErrors {
  firstName?: string;
  surname?: string;
  dateOfBirth?: string;
}

const filter = createFilterOptions<Athlete>();

const ADD_NEW = "Add new";

const AthleteSelect: React.FunctionComponent<AthleteSelectProps> = (props) => {
  const cancelToken = useCancelToken();

  const [saving, setSaving] = useState<boolean>(false);

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState<Unsaved<Athlete>>({
    firstName: "",
    surname: "",
    dateOfBirth: "",
  });

  const handleClose = () => {
    setDialogValue({
      firstName: "",
      surname: "",
      dateOfBirth: "",
    });
    setFieldErrors({});
    toggleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setSaving(true);

    postAthlete(dialogValue, cancelToken)
      .then((createdAthlete) => {
        setSaving(false);
        props.onAthleteSaved(createdAthlete);
        props.onChange(createdAthlete.id);
        handleClose();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSaving(false);
          if (err.response?.status === 400 && err.response?.data) {
            setFieldErrors({ ...err.response.data });
          } else {
            showNotification({
              message: "Failed to save athlete, please reload the page and try again.",
              severity: "error",
            });
          }
        }
      });
  };

  return (
    <React.Fragment>
      <Autocomplete
        freeSolo
        data-testid="athlete-autocomplete"
        value={props.value}
        options={props.choices || []}
        getOptionLabel={(option) => (option.id === ADD_NEW ? ADD_NEW : `${option.surname}, ${option.firstName}`)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Athlete"
            error={Boolean(props.error)}
            helperText={props.error}
            data-testid="athlete-autocomplete-input"
          />
        )}
        onChange={(event: unknown, newValue: string | Athlete | null) => {
          if (typeof newValue === "string") {
            return;
          }
          if (newValue && newValue.id === ADD_NEW) {
            toggleOpen(true);
            return;
          }
          if (newValue) {
            props.onChange(newValue.id);
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "clear") {
            props.onChange("");
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          filtered.push({
            id: ADD_NEW,
            firstName: "",
            surname: "",
            dateOfBirth: "",
          });

          return filtered;
        }}
        disabled={saving}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" data-testid="athlete-add-dialog">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a new athlete</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.firstName}
              onChange={(event) => setDialogValue({ ...dialogValue, firstName: event.target.value })}
              label="First Name"
              type="text"
              error={Boolean(fieldErrors.firstName)}
              helperText={fieldErrors.firstName}
              fullWidth
              inputProps={{ "data-testid": "athlete-first-name" }}
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.surname}
              onChange={(event) => setDialogValue({ ...dialogValue, surname: event.target.value })}
              label="Surname"
              type="text"
              error={Boolean(fieldErrors.surname)}
              helperText={fieldErrors.surname}
              fullWidth
              inputProps={{ "data-testid": "athlete-surname" }}
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.dateOfBirth}
              onChange={(event) => setDialogValue({ ...dialogValue, dateOfBirth: event.target.value })}
              label="Date of Birth"
              type="date"
              error={Boolean(fieldErrors.dateOfBirth)}
              helperText={fieldErrors.dateOfBirth}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ "data-testid": "athlete-dob" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" data-testid="athlete-submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default AthleteSelect;
