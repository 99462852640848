import { createContext } from "react";
import Rollbar from "rollbar";

/**
 * Context so that rollbar can be used around the app with the useContext hook.
 * This prevents us from passing it around through props.
 */
const RollbarContext = createContext<Rollbar | undefined>(undefined);

export default RollbarContext;
