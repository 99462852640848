import React, { useMemo, FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Grid, Tabs, Tab, Box, Divider } from "@material-ui/core";
import ExportButtons from "./ExportButtons";
import DataExplorer from "./DataExplorer";
import Throbber from "../throbber/Throbber";
import { FullAssessment, useFullAssessmentEffect, useFullAssessmentsEffect } from "hooks/useFullAssessment";
import { Measure, Step } from "utils/api";
import LtMethodSelect from "./LTMethodSelect";
import ErrorPage from "../error-page/ErrorPage";
import TestDetails from "./TestDetails";
import Graph from "./Graph";
import clsx from "clsx";
import styles from "./AssessmentView.module.scss";
import LTInfo from "./LTInfo";
import { useAVReducer } from "./hooks/useAVReducer";
import { isLt1Method, isLt2Method, LtMethod } from "./types";
import { useMeasures } from "hooks/useMeasures";
import TimelineGraph from "./TimelineGraph";
import ComparisonSelect from "./ComparisonSelect";
import { parseISO } from "date-fns";
import DataTable from "components/data-table/DataTable";
import { getColumnFromMeasure } from "components/data-table/utils";
import useFormatter from "../../hooks/useFormatter";
import LtTable from "./LtTable";

const DATA_EXPLORER = "DE";

const isBottomRow = (numItems: number, item: number) => {
  if (numItems % 2 === 0) {
    return item === numItems || item === numItems - 1;
  }

  return item === numItems;
};

const AssessmentView: FC = () => {
  const [
    {
      assessment,
      selectedComparisonIds,
      comparisonData: { ids: comparisonIds, assessments: comparisonAssessments, error: comparisonError },
      workMeasure,
      annotations,
      interpolatedStep,
      explorerInput,
      graphTab,
      lt1Method,
      lt1Value,
      lt2Method,
      lt2Value,
    },
    avDispatch,
  ] = useAVReducer();

  const formatter = useFormatter();

  const actualAssessment = assessment.data;

  const timelineAssessments: Array<FullAssessment> = useMemo(() => {
    if (!(actualAssessment && comparisonAssessments && comparisonIds)) {
      return [];
    }
    const currentDate = parseISO(actualAssessment.testDate);
    const assessments = comparisonIds.map((id) => comparisonAssessments[id]);
    const index = assessments.findIndex((a) => parseISO(a.testDate) < currentDate);
    if (index === -1) {
      assessments.push(actualAssessment);
    } else {
      assessments.splice(index, 0, actualAssessment);
    }
    return assessments;
  }, [actualAssessment, comparisonAssessments, comparisonIds]);

  const { assessmentId } = useParams<{ assessmentId: string }>();

  const [measures, measuresError] = useMeasures();

  const onAssessmentUpdate = useCallback(
    (assessment, error) => avDispatch({ type: "setAssessment", assessment, error, measures }),
    [measures, avDispatch]
  );

  const onComparisonSelected = useCallback(
    (assessmentIds) => {
      avDispatch({ type: "setSelectedComparisonIds", ids: assessmentIds, measures });
    },
    [avDispatch, measures]
  );

  useFullAssessmentEffect(assessmentId, measures, onAssessmentUpdate);

  const onComparisonsFetched = useCallback(
    (assessments: Array<FullAssessment> | null, error: string | null) =>
      avDispatch({
        type: "setComparisonData",
        assessments: assessments ? assessments.filter((a) => a.id !== assessmentId) : null,
        error,
      }),
    [assessmentId, avDispatch]
  );

  useFullAssessmentsEffect(
    assessment.data?.athlete.id ?? null,
    assessment.data?.protocol.id ?? null,
    measures,
    onComparisonsFetched
  );

  const fitMeasures: Measure[] = useMemo(() => {
    const rtn: Measure[] = [];
    if (actualAssessment && measures && workMeasure) {
      for (const id of actualAssessment.measures) {
        const measure = measures.get(id);
        const regression = actualAssessment.regressions.get(id);
        if (
          measure &&
          workMeasure.id !== id &&
          regression?.coefficients &&
          actualAssessment.steps.some((step) => Boolean(step[id]))
        ) {
          rtn.push(measure);
        }
      }
    }
    return rtn;
  }, [actualAssessment, measures, workMeasure]);

  const lactateMeasure = fitMeasures.find((measure) => measure.id === "lactate");

  const columns = useMemo(() => {
    if (assessment && assessment.data) {
      const allMeasureIds = new Set([
        ...assessment.data.protocol.measures.map((measure) => measure.id),
        ...assessment.data.measures,
      ]);

      return Array.from(allMeasureIds)
        .map((measureId) => measures?.get(measureId))
        .filter((m): m is Measure => m !== undefined)
        .map(getColumnFromMeasure);
    }
  }, [assessment, measures]);

  const extraSteps = useMemo(
    () =>
      annotations.main
        .filter((annotation) => annotation.name !== DATA_EXPLORER)
        .map((annotation) => {
          const x = annotation.value;
          const values = columns?.reduce((acc: Step, column) => {
            const measureId = column.id;
            const regression = actualAssessment?.regressions.get(measureId);
            if (regression) {
              const value = regression.getAt(x);
              if (!Number.isNaN(value)) {
                acc[measureId] = formatter(measureId, value.toString()) ?? null;
              }
            }
            return acc;
          }, {});

          return {
            ...values,
            time: annotation.name,
          };
        }),
    [annotations.main, actualAssessment, columns]
  );

  const flatAnnotations = useMemo(() => {
    return [...annotations.main, ...annotations.comparisons];
  }, [annotations]);

  const extraAssessments: Array<FullAssessment> = useMemo(() => {
    if (!comparisonAssessments) {
      return [];
    }
    return selectedComparisonIds.map((id) => comparisonAssessments[id]);
  }, [comparisonAssessments, selectedComparisonIds]);

  if (assessment.error || measuresError) {
    return <ErrorPage />;
  }

  if (!actualAssessment || !measures || !workMeasure || !columns) {
    return <Throbber message="Loading..." />;
  }

  return (
    <Grid container className={styles.container} data-testid="assessment-view">
      <Grid item xs={12}>
        <TestDetails assessment={actualAssessment} />
      </Grid>
      <Grid item xs={12} className={styles.graphView}>
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
          <Grid item>
            <Tabs
              classes={{ flexContainer: styles.tabsContainer }}
              value={graphTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, value) => {
                if (value === "lactate" || value === "multiple" || value === "longitudinal") {
                  avDispatch({ type: "changeGraphTab", graphTab: value });
                }
              }}
            >
              <Tab className={styles.tab} classes={{ selected: styles.selected }} label="lactate" value="lactate" />
              <Tab
                className={styles.tab}
                classes={{ selected: styles.selected }}
                label="multiple"
                value="multiple"
                disabled={fitMeasures.length <= 1}
              />
              <Tab
                className={styles.tab}
                classes={{ selected: styles.selected }}
                label="timeline"
                value="longitudinal"
                disabled={Boolean(timelineAssessments.length < 2)}
              />
            </Tabs>
          </Grid>
          <Grid item>
            <Box className={styles.methodSelects}>
              <Box mr={2}>
                <LtMethodSelect
                  ltType={"lt1"}
                  value={lt1Method}
                  minWidth={78}
                  onChange={(newMethod: LtMethod) => {
                    if (isLt1Method(newMethod)) {
                      avDispatch({ type: "changeLt1Method", lt1Method: newMethod, measures });
                    }
                  }}
                />
              </Box>
              <Box mr={2}>
                <LtMethodSelect
                  ltType={"lt2"}
                  value={lt2Method}
                  minWidth={115}
                  onChange={(newMethod: LtMethod) => {
                    if (isLt2Method(newMethod)) {
                      avDispatch({ type: "changeLt2Method", lt2Method: newMethod, measures });
                    }
                  }}
                />
              </Box>
              <Box mr={1}>
                <ComparisonSelect
                  ids={comparisonIds}
                  selectedIds={selectedComparisonIds}
                  assessments={comparisonAssessments}
                  error={comparisonError}
                  onChange={onComparisonSelected}
                />
              </Box>
              <Box mr={1}>
                <LTInfo />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={styles.graphContainer}>
          <Box className={clsx({ [styles.hidden]: graphTab !== "lactate" })}>
            {lactateMeasure && (
              <Graph
                assessment={actualAssessment}
                comparisons={extraAssessments}
                fitMeasure={lactateMeasure}
                annotations={flatAnnotations}
                workMeasure={workMeasure}
                lt1={lt1Value || null}
                lt2={lt2Value || null}
                showLegend
              />
            )}
          </Box>
          <Grid container className={clsx({ [styles.hidden]: graphTab !== "multiple" })}>
            {fitMeasures.map((measure, index) => (
              <Grid
                key={measure.id}
                className={clsx(styles.plot, {
                  [styles.left]: index % 2 === 0,
                  [styles.bottom]: isBottomRow(fitMeasures.length, index + 1),
                })}
                item
                xs={6}
              >
                <Graph
                  assessment={actualAssessment}
                  comparisons={extraAssessments}
                  fitMeasure={measure}
                  annotations={flatAnnotations}
                  workMeasure={workMeasure}
                  minHeightVH={fitMeasures.length > 2 ? "27.5vh" : "55vh"}
                  lt1={lt1Value || null}
                  lt2={lt2Value || null}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container className={clsx({ [styles.hidden]: graphTab !== "longitudinal" })}>
            <TimelineGraph
              assessments={timelineAssessments}
              controlled={workMeasure}
              lt1Method={lt1Method}
              lt2Method={lt2Method}
            />
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataExplorer
          assessmentId={assessmentId}
          measures={measures}
          regressions={actualAssessment.regressions}
          interpolatedStep={interpolatedStep}
          explorerInput={explorerInput}
          columns={columns}
          steps={actualAssessment.steps}
          avDispatch={avDispatch}
        />
        <Box mt={1}>
          <LtTable columns={columns} rows={extraSteps} steps={actualAssessment.steps} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider className={styles.divider} flexItem />
      </Grid>
      <Grid item xs={12} className={styles.dataTable} data-testid="assessment-view-data-table">
        <Grid container direction="column">
          <Box display="flex" mb={2}>
            <ExportButtons
              assessment={actualAssessment}
              columns={columns}
              annotationSteps={extraSteps}
              regression={actualAssessment.regressions.get("lactate")}
              interpolatedStep={interpolatedStep}
            />
          </Box>
          <DataTable rows={actualAssessment.steps} columns={columns} formatter={formatter} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssessmentView;
