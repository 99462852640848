import React, { useState, FC } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Box, Button, Typography } from "@material-ui/core";
import { PolyFit } from "models/PolyFit";
import { FullAssessment } from "hooks/useFullAssessment";
import { Column } from "components/data-table/DataTable";
import { Step } from "../../utils/api";

interface Props {
  assessment: FullAssessment;
  columns: Column[];
  annotationSteps?: Step[];
  regression?: PolyFit;
  interpolatedStep: Step | null;
}

const ExportButtons: FC<Props> = ({ assessment, columns, annotationSteps, regression, interpolatedStep }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  let assessmentTable = "";
  let assessmentOneLine = "";
  let ltsOneLine = "";
  let interpolatedOneLine = "";
  let headings = "";

  const regressionCoefficients = regression?.coefficients.join("\t");

  columns.forEach((column) => {
    const value = column.name;
    if (value) {
      headings += value;
    }
    headings += "\t";
  });

  assessment.steps.forEach((step) => {
    let retval = "";
    columns.forEach((column) => {
      const value = step[column.id];
      if (value) {
        retval += value;
      }
      retval += "\t";
    });

    assessmentTable += retval.slice(0, -1) + "\n";
    assessmentOneLine += retval;
  });

  if (annotationSteps) {
    annotationSteps.forEach((step) => {
      let retval = "";
      columns.forEach((column) => {
        const value = step[column.id];
        if (value) {
          retval += value;
        }
        retval += "\t";
      });

      assessmentTable += retval.slice(0, -1) + "\n";
      assessmentOneLine += retval;
      ltsOneLine += retval;
    });
  }

  if (interpolatedStep) {
    columns.forEach((column) => {
      const value = interpolatedStep[column.id];
      if (value) {
        interpolatedOneLine += value;
      }
      interpolatedOneLine += "\t";
    });
  }

  assessmentTable = assessmentTable.slice(0, -1);
  assessmentOneLine = assessmentOneLine.slice(0, -1);
  ltsOneLine = ltsOneLine.slice(0, -1);
  headings = headings.slice(0, -1);

  return (
    <Box>
      <Typography color="primary">
        Export to Clipboard
        {copySuccess && (
          <Typography style={{ color: "#2e9e46" }} display="inline">
            {" "}
            copied!
          </Typography>
        )}
      </Typography>
      <CopyToClipboard
        onCopy={() => {
          setCopySuccess(true);
        }}
        options={{ format: "text/plain" }}
        text={headings}
      >
        <Button color="primary" variant="outlined" style={{ marginRight: "0.5rem" }}>
          Headings
        </Button>
      </CopyToClipboard>
      <CopyToClipboard
        onCopy={() => {
          setCopySuccess(true);
        }}
        options={{ format: "text/plain" }}
        text={assessmentTable}
      >
        <Button color="primary" variant="outlined" style={{ marginRight: "0.5rem" }}>
          as Table
        </Button>
      </CopyToClipboard>

      <CopyToClipboard
        onCopy={() => {
          setCopySuccess(true);
        }}
        options={{ format: "text/plain" }}
        text={assessmentOneLine}
      >
        <Button color="primary" variant="outlined" style={{ marginRight: "0.5rem" }}>
          as One Line
        </Button>
      </CopyToClipboard>

      <CopyToClipboard
        onCopy={() => {
          setCopySuccess(true);
        }}
        options={{ format: "text/plain" }}
        text={regressionCoefficients ?? ""}
      >
        <Button color="primary" variant="outlined" style={{ marginRight: "0.5rem" }} disabled={!regressionCoefficients}>
          Regression Coefficients
        </Button>
      </CopyToClipboard>

      {annotationSteps && (
        <CopyToClipboard
          onCopy={() => {
            setCopySuccess(true);
          }}
          options={{ format: "text/plain" }}
          text={ltsOneLine}
        >
          <Button color="primary" variant="outlined" style={{ marginRight: "0.5rem" }}>
            {"only LT's"}
          </Button>
        </CopyToClipboard>
      )}
      {interpolatedStep && (
        <CopyToClipboard
          onCopy={() => {
            setCopySuccess(true);
          }}
          options={{ format: "text/plain" }}
          text={interpolatedOneLine}
        >
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: "0.5rem" }}
            disabled={!/\S/.test(interpolatedOneLine)}
          >
            {"only DE"}
          </Button>
        </CopyToClipboard>
      )}
    </Box>
  );
};

export default ExportButtons;
