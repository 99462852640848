import React, { FC, useState } from "react";
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Tester, Unsaved } from "utils/api";
import { useTesters } from "hooks/useTesters";

interface TesterSelectProps {
  selectedTesterId: string | null;
  onChange: (testerId: string) => void;
}

const filter = createFilterOptions<Tester>();

const ADD_NEW = "Add new";

const TesterSelect: FC<TesterSelectProps> = ({ selectedTesterId, onChange }) => {
  const { testers, loading, error, fieldErrors, addTester } = useTesters();

  // TODO push this into the hook and wrap all our stuff in error boundaries.
  if (error) {
    throw Error(error);
  }

  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState<Unsaved<Tester>>({
    firstName: "",
    surname: "",
  });

  const handleClose = () => {
    setDialogValue({
      firstName: "",
      surname: "",
    });
    toggleOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const tester = await addTester(dialogValue);
    onChange(tester ? tester.id : "");
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        freeSolo
        value={(selectedTesterId && testers?.find((t) => t.id === selectedTesterId)) || null}
        options={testers || []}
        getOptionLabel={(option) => (option.id === ADD_NEW ? ADD_NEW : `${option.surname}, ${option.firstName}`)}
        renderInput={(params) => <TextField {...params} label="Tester" />}
        data-testid="tester-autocomplete"
        onChange={(event: unknown, newValue: string | Tester | null) => {
          if (typeof newValue === "string") {
            return;
          }
          if (newValue && newValue.id === ADD_NEW) {
            toggleOpen(true);
            return;
          }
          if (newValue) {
            onChange(newValue.id);
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "clear") {
            onChange("");
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          filtered.push({
            id: ADD_NEW,
            firstName: "",
            surname: "",
          });

          return filtered;
        }}
        disabled={loading}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" data-testid="tester-add-dialog">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a new tester</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.firstName}
              onChange={(event) => setDialogValue({ ...dialogValue, firstName: event.target.value })}
              label="First Name"
              type="text"
              error={Boolean(fieldErrors.firstName)}
              helperText={fieldErrors.firstName}
              fullWidth
              inputProps={{ "data-testid": "tester-first-name" }}
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.surname}
              onChange={(event) => setDialogValue({ ...dialogValue, surname: event.target.value })}
              label="Surname"
              type="text"
              error={Boolean(fieldErrors.surname)}
              helperText={fieldErrors.surname}
              fullWidth
              inputProps={{ "data-testid": "tester-surname" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" data-testid="tester-submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default TesterSelect;
