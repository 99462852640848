export interface AuthState {
  authenticated: boolean;
  authorised: boolean;
  email: string | null;
  loading: boolean;
  error: string | null;
}

interface AuthenticatedAction {
  type: "authenticated";
  payload: {
    authenticated: boolean;
    authorised: boolean;
  };
}

interface EmailAction {
  type: "email";
  payload: {
    email: string;
  };
}

interface LoadingAction {
  type: "loading";
}

interface FailedAction {
  type: "failed";
  payload: {
    error: string;
  };
}

type AuthAction = AuthenticatedAction | EmailAction | LoadingAction | FailedAction;

export const initialState: AuthState = {
  authenticated: false,
  authorised: false,
  email: null,
  loading: true,
  error: null,
};

export default function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "authenticated":
      return {
        ...state,
        authenticated: action.payload.authenticated,
        authorised: action.payload.authorised,
        loading: false,
      };
    case "email":
      return {
        ...state,
        email: action.payload.email,
      };
    case "loading":
      return {
        ...state,
        error: null,
        loading: true,
      };
    case "failed":
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
  }
}
