import React, { ChangeEvent } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { Lt1Method, Lt2Method, isLt1Method, isLt2Method } from "./types";

const LT1Methods: Lt1Method[] = ["Measured", "Calculated"];
const Lt2Methods: Lt2Method[] = ["Modified D-Max", "D-Max", "4mmol"];

interface FunctionalProps {
  ltType?: "lt1" | "lt2";
  value: Lt1Method | Lt2Method;
  minWidth?: number;
  onChange(newValue: Lt1Method | Lt2Method): void;
}

interface StyleProps {
  minWidth?: number;
}

type Props = FunctionalProps & StyleProps;

const useStyles = makeStyles({
  select: {
    minWidth: (props: StyleProps) => props.minWidth || "auto",
    padding: "10px 14px",
  },
});

function LtMethodSelect({ ltType = "lt1", value, minWidth, onChange }: Props) {
  const styles = useStyles({ minWidth });
  const intialSelection = ltType === "lt1" ? "Measured" : "Modified D-Max";

  const getSuitableValue = (event: ChangeEvent<{ value: unknown }>): void => {
    const { value } = event.target;
    if (isLt1Method(value) || isLt2Method(value)) {
      return onChange(value);
    }
  };

  const label = `${ltType.toUpperCase()} Method`;
  const items: string[] = ltType === "lt1" ? LT1Methods : Lt2Methods;

  return (
    <FormControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        classes={{ select: styles.select }}
        value={value || intialSelection}
        onChange={getSuitableValue}
        label={label}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default LtMethodSelect;
