import React, {
  ClipboardEvent,
  createRef,
  FC,
  KeyboardEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import styles from "./DataTable.module.scss";
import clsx from "clsx";
import { showNotification } from "utils/notifications";
import { Step } from "../../utils/api";

const ascArrow = "▲";
const descArrow = "▼";

/** The data fields that need to be supplied for each column in the DataTable. */
export interface ColumnData {
  /** A unique identifier for the column. */
  id: string;
  /** A display name used for identifying the column in the UI. */
  name: string;
  /** Whether the column data can be edited in each row. */
  editable?: boolean;
}

/** Extends the column data with a headerRenderer function. */
export interface Column extends ColumnData {
  /**
   * Allows a custom component to be rendered in the header cell.
   *
   * If you return something other than a string the cell will not be
   * able to be pasted into excel.
   */
  headerRenderer?(columnData: ColumnData): ReactNode;
}

interface Props {
  /** The columns for the data table, the ordering in the array is preserved. */
  readonly columns: Column[];
  /**
   * An array of steps used for table rows. The ordering of the columns will be used to extract
   * the measures out in the correct order.
   */
  readonly rows: Step[];
  /** Whether the ability to paste data into the table is enabled. The default value is true. */
  pasteEnabled?: boolean;
  /** Whether the rows can be sorted by a column or not. The default value is true. */
  sortEnabled?: boolean;
  /** Whether to show the header row. */
  showHeaders?: boolean;
  /** Custom data formatter */
  formatter?: (id?: string, value?: string | null) => string | null | undefined;
  /** A function which is called when rows are added, removed, or updated. */
  onUpdate?(newRows: Step[]): void;
}

interface SortState {
  sortColumnId?: string;
  sortOrder?: "asc" | "desc";
}

const floatRegex = /^[+-]?\d+(\.\d+)?$/;

/**
 * This compares two cell values for sorting.
 *
 * We have an unfortunate condition happening where out string values for steps are actually numbers.
 * To combat this and also to make sure numbers always get compared with numbers, we check the types.
 * In the event we are trying to compare a number to a string we always assume the string is greater
 * than the number to keep ordering somewhat consistant.
 */
const compareCells = (a: unknown, b: unknown) => {
  const aVal = typeof a === "string" && floatRegex.test(a) ? parseFloat(a) : a;
  const bVal = typeof b === "string" && floatRegex.test(b) ? parseFloat(b) : b;

  if (typeof aVal === "number" && typeof bVal === "number") {
    return aVal - bVal;
  } else if (typeof aVal === "string" && typeof bVal === "string") {
    return aVal.localeCompare(bVal);
  } else if (typeof aVal === "string" && typeof bVal === "number") {
    return 1;
  } else if (typeof aVal === "number" && typeof bVal === "string") {
    return -1;
  } else if (aVal === null && bVal !== null) {
    return -1;
  } else if (aVal !== null && bVal === null) {
    return 1;
  }
  return 0;
};

/**
 * A table for showing adapt data values and allowing editing and pasting to excel.
 *
 * The order of the columns prop determines the order of the columns in the table.
 * The order of the rows prop determines the order of the rows when sorting is not applied.
 */
const DataTable: FC<Props> = ({
  columns,
  rows,
  pasteEnabled = true,
  sortEnabled = true,
  showHeaders = true,
  formatter,
  onUpdate,
}) => {
  const [{ sortColumnId, sortOrder }, setSortState] = useState<SortState>({});
  const [activeCell, setActiveCell] = useState<MutableRefObject<HTMLTableCellElement | null> | null>(null);

  const dataCellRefs = useMemo(() => {
    const rtn: MutableRefObject<HTMLTableCellElement | null>[][] = [];
    // Create a MxN matrix of refs for kb navigation
    for (let row = 0; row < rows.length; row++) {
      rtn.push([]);
      for (let col = 0; col < columns.length; col++) {
        rtn[row].push(createRef());
      }
    }
    return rtn;
  }, [columns, rows]);

  /**
   * Gets the [row, column] position of the data table cell that is active.
   *
   * This will return [-1, -1] if there is no active table cell.
   */
  const getActiveCellPosition = () => {
    let activeCellRowIndex = -1;
    let activeCellColumnIndex = -1;

    dataCellRefs.some((row, i) =>
      row.some((ref, j) => {
        if (document.activeElement && ref.current === document.activeElement) {
          activeCellRowIndex = i;
          activeCellColumnIndex = j;
          return true;
        }
      })
    );

    return [activeCellRowIndex, activeCellColumnIndex];
  };

  /**
   * This keyboard event handler attempts to mimic some of the behaviour
   * we see while navigating excel with the keyboard.
   *
   * Excel has some funny behaviour around moving around a cells content
   * vs moving between cells. This takes a halfway approach and navigates
   * between cells if we are at the respective end of the string.
   */
  const onKeyboardNav = useCallback(
    (event: KeyboardEvent<HTMLTableCellElement>) => {
      const [currentRow, currentCol] = getActiveCellPosition();

      // In the case where a cell isnt active do nothing.
      if (currentRow === -1 || currentCol === -1) {
        return;
      }

      const focusedContent = document?.getSelection() || window?.getSelection();
      if (event.key === "ArrowLeft" && currentCol - 1 >= 0 && (!focusedContent || focusedContent.focusOffset === 0)) {
        event.preventDefault();
        setActiveCell(dataCellRefs[currentRow][currentCol - 1]);
      } else if (
        event.key === "ArrowRight" &&
        currentCol + 1 < dataCellRefs[currentRow].length &&
        (!focusedContent || focusedContent.focusOffset === (focusedContent.focusNode?.textContent?.length || 0))
      ) {
        event.preventDefault();
        setActiveCell(dataCellRefs[currentRow][currentCol + 1]);
      } else if (event.key === "ArrowUp" && currentRow - 1 >= 0) {
        event.preventDefault();
        setActiveCell(dataCellRefs[currentRow - 1][currentCol]);
      } else if (event.key === "ArrowDown" && currentRow + 1 < dataCellRefs.length) {
        event.preventDefault();
        setActiveCell(dataCellRefs[currentRow + 1][currentCol]);
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (currentRow + 1 < dataCellRefs.length) {
          setActiveCell(dataCellRefs[currentRow + 1][currentCol]);
        } else if (currentCol + 1 < dataCellRefs[currentRow].length) {
          setActiveCell(dataCellRefs[0][currentCol + 1]);
        }
      }
    },
    [dataCellRefs]
  );

  // We add the initial index of the step in this data structure so that we can
  // map out the original order when calling onUpdate
  const sortedRows = rows.map((step, index) => ({ step, initialPosition: index }));
  if (sortColumnId && sortOrder) {
    sortedRows.sort((a, b) =>
      sortOrder === "asc"
        ? compareCells(a.step[sortColumnId], b.step[sortColumnId])
        : compareCells(b.step[sortColumnId], a.step[sortColumnId])
    );
  }

  /**
   * This handles the a paste into the table. It respects the cell position the the data was pasted into.
   *
   * In the event the user pastes in too many columns for the positino it will show a notification.
   *
   * In the event there is no active cell or no data to paste this is a no-op.
   */
  const handlePaste = (event: ClipboardEvent<HTMLTableDataCellElement>) => {
    const content = event.clipboardData.getData("text/plain");
    const inputRows = content.split(/\r?\n/);

    if (inputRows.length > 0 && pasteEnabled && onUpdate) {
      event.preventDefault();

      const [activeCellRowIndex, activeCellColumnIndex] = getActiveCellPosition();

      // In the case where a cell isnt active do nothing.
      if (activeCellRowIndex > -1 && activeCellColumnIndex > -1) {
        const newRows: Step[] = _.cloneDeep(rows);

        for (let i = 0; i < inputRows.length; i++) {
          const rowIndexToUse = i + activeCellRowIndex;
          if (rowIndexToUse >= newRows.length) {
            newRows.push({});
          }

          const newStep: Step = newRows[rowIndexToUse];
          const inputColumns = inputRows[i].split("\t");

          if (inputColumns.length + activeCellColumnIndex > columns.length) {
            showNotification({ severity: "warning", message: "Your data has too many columns to paste in." });
            return;
          } else if (inputColumns.length > 0) {
            for (let j = 0; j < inputColumns.length; j++) {
              // Keep no values consistent, i.e. if empty string set as null
              newStep[columns[j + activeCellColumnIndex].id] = inputColumns[j] || null;
            }
          }
        }
        onUpdate(newRows);
      }
    }
  };

  useEffect(() => {
    activeCell?.current?.focus();
  }, [activeCell]);

  return (
    <div>
      <table className={styles.table}>
        {showHeaders ? (
          <thead>
            <tr>
              {columns.map((col) => (
                <th
                  key={col.id}
                  className={clsx(styles.headerCell, { [styles.sortable]: sortedRows.length > 1 })}
                  onClick={() => {
                    if (!sortEnabled || sortedRows.length <= 1) {
                      return;
                    } else if (sortColumnId !== col.id) {
                      setSortState({ sortColumnId: col.id, sortOrder: "asc" });
                    } else if (sortOrder === "asc") {
                      setSortState({ sortColumnId: col.id, sortOrder: "desc" });
                    } else {
                      setSortState({});
                    }
                  }}
                >
                  <div className={styles.headerCellContent}>
                    {col.headerRenderer ? col.headerRenderer(col) : <span>{col.name}</span>}
                    {sortColumnId === col.id && sortOrder === "asc" && <span>{ascArrow}</span>}
                    {sortColumnId === col.id && sortOrder === "desc" && <span>{descArrow}</span>}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {sortedRows.map((trackedStep, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                // This is potentially interactable as the prop contentEditable can be true
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <td
                  data-testid={`data-table-cell-${colIndex}-${rowIndex}`}
                  key={col.id}
                  suppressContentEditableWarning={true}
                  onKeyDown={onKeyboardNav}
                  ref={dataCellRefs[rowIndex][colIndex]}
                  className={clsx(styles.cell, { [styles.editable]: col.editable })}
                  contentEditable={col.editable}
                  onPaste={handlePaste}
                  onBlur={({ target }) => {
                    if (col.editable && onUpdate && target instanceof HTMLElement) {
                      const newRows = rows.map((step) => ({ ...step }));
                      if (target.textContent !== newRows[trackedStep.initialPosition][col.id]) {
                        newRows[trackedStep.initialPosition][col.id] = target.textContent || null;
                        onUpdate(newRows);
                      }
                    }
                  }}
                >
                  {formatter ? formatter(col.id, trackedStep.step[col.id]) : trackedStep.step[col.id]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
