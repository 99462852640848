import React, { FC } from "react";
import { Typography, Grid } from "@material-ui/core";
import styles from "./ErrorPage.module.scss";

const ErrorPage: FC = () => {
  return (
    <Grid className={styles.container} container justify="center" alignItems="center">
      <Grid item>
        <Typography variant="h5">This page failed to load, please refresh and try again.</Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
