import { useEffect, useState } from "react";
import axios from "axios";
import { getAssessmentInterpolation } from "utils/api";
import { useCancelToken } from "hooks/useCancelToken";
import { showNotification } from "utils/notifications";

export function useAssessmentInterpolation(
  assessmentId: string,
  measureId: string | null,
  measureValue: string | null,
  error: boolean,
  onUpdate: (interpolations: Record<string, number> | null) => void
): boolean {
  const [loading, setLoading] = useState(false);

  const cancelToken = useCancelToken();

  useEffect(() => {
    // Note measureValue is a string so we dont need to worry about 0 atm
    if (assessmentId && measureId && measureValue && !error) {
      setLoading(true);
      getAssessmentInterpolation(assessmentId, measureId, measureValue, cancelToken)
        .then(onUpdate)
        .catch((err) => {
          onUpdate(null);

          if (!axios.isCancel(err)) {
            showNotification({
              message: `Failed to get interpolated values. Please refresh your browser and try again.`,
              severity: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [assessmentId, measureId, measureValue, error, onUpdate, cancelToken]);

  return loading;
}
