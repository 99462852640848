import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import history from "./history";
import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN, AUTH_SCOPE } from "../settings";

let authClient: Auth0Client | null = null;

const AUTH_OPTIONS = {
  domain: AUTH_DOMAIN,
  client_id: AUTH_CLIENT_ID,
  audience: AUTH_AUDIENCE,
  redirect_uri: `${window.location.origin}/`,
  scope: AUTH_SCOPE,
};

async function initAuthClient() {
  try {
    const authClient = await createAuth0Client(AUTH_OPTIONS);
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("code") && searchParams.has("state")) {
      const result = await authClient.handleRedirectCallback();
      const targetUrl = result.appState?.targetUrl || "/";
      history.push(targetUrl);
    }
    return authClient;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function getAuthClient() {
  if (authClient) {
    return authClient;
  } else {
    authClient = await initAuthClient();
    return authClient;
  }
}

export function eject() {
  const auth0Client = new Auth0Client(AUTH_OPTIONS);
  auth0Client.logout({
    returnTo: `${window.location.origin}/`,
  });
}
