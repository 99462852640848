import { getAthletes, Athlete } from "utils/api";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useCancelToken } from "./useCancelToken";
import _ from "lodash";

export type FetchedAthletes = [Athlete[] | null, string | null, (athlete: Athlete) => void];

export function useAthletes(): FetchedAthletes {
  const [athletes, setAthletes] = useState<Array<Athlete> | null>(null);
  const [error, setError] = useState<string | null>(null);

  const cancelToken = useCancelToken();

  useEffect(() => {
    getAthletes()
      .then(setAthletes)
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err.toString());
        }
      });
  }, [cancelToken]);

  const addAthlete = useCallback(
    (newAthlete: Athlete) => {
      if (athletes) {
        setAthletes(_.sortBy([...athletes, newAthlete], (athlete) => athlete.surname.toLowerCase()));
      } else {
        throw new Error("Stopped an attempt to add an athlete before existing athletes have been fetched.");
      }
    },
    [athletes, setAthletes]
  );

  return [athletes, error, addAthlete];
}
