import ErrorPage from "components/error-page/ErrorPage";
import ProtocolEditView from "components/protocol-edit/ProtocolEditView";
import Throbber from "components/throbber/Throbber";
import { useMeasures } from "hooks/useMeasures";
import { useProtocols } from "hooks/useProtocols";
import React, { FC, useState } from "react";
import { ApiProtocol } from "utils/api";
import ProtocolListItem from "./ProtocolListItem";

import styles from "./ProtocolListView.module.scss";

const ProtocolListView: FC = () => {
  const {
    protocols,
    error: protocolsError,
    loading: protocolsLoading,
    fieldErrors,
    addProtocol,
    updateProtocol,
  } = useProtocols();
  const [measures, measuresError] = useMeasures();
  const [selectedProtocol, setSelectedProtocol] = useState<ApiProtocol | undefined | null>();

  if (protocolsError || measuresError) {
    return <ErrorPage />;
  }

  if (!protocols || !measures) {
    return <Throbber message="Loading..." />;
  }

  return (
    <div data-testid="protocol-list" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <div style={{ textAlign: "center", width: "70vw", padding: "40px" }}>
        <div
          className={styles.itemContainer}
          onClick={() => setSelectedProtocol(null)}
          onKeyDown={() => setSelectedProtocol(null)}
          role="button"
          tabIndex={0}
        >
          Create New Protocol
        </div>
        {protocols.map((protocol) => (
          <ProtocolListItem
            key={protocol.id}
            protocol={protocol}
            measures={measures}
            onSelect={() => setSelectedProtocol(protocols.find((p) => p.id === protocol.id))}
          />
        ))}
      </div>
      {selectedProtocol !== undefined && (
        <div style={{ textAlign: "center", width: "30vw", padding: "40px" }}>
          <ProtocolEditView
            protocol={selectedProtocol}
            loading={protocolsLoading}
            fieldErrors={fieldErrors}
            addProtocol={(newProtocol) =>
              addProtocol(newProtocol).then((savedProtocol) => savedProtocol && setSelectedProtocol(savedProtocol))
            }
            updateProtocol={(newProtocol) =>
              updateProtocol(newProtocol).then((savedProtocol) => savedProtocol && setSelectedProtocol(savedProtocol))
            }
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(ProtocolListView);
