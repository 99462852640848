import RollbarContext from "context/RollbarContext";
import { useContext, useMemo } from "react";

const useLogging = () => {
  const rollbar = useContext(RollbarContext);

  return useMemo(
    () => ({
      info: (message: string) => {
        console.info(message);
        rollbar?.info(message);
      },
      warn: (message: string, err?: Error) => {
        if (err) {
          console.warn(message, err);
          rollbar?.warn(message, err);
        } else {
          console.warn(message);
          rollbar?.warn(message);
        }
      },
      error: (message: string, err?: Error) => {
        if (err) {
          console.error(message, err);
          rollbar?.error(message, err);
        } else {
          console.error(message);
          rollbar?.error(message);
        }
      },
    }),
    [rollbar]
  );
};

export default useLogging;
