import React, { FC, useCallback } from "react";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import { toAusDate } from "../../utils/misc";
import styles from "./AssessmentView.module.scss";
import { FullAssessment } from "../../hooks/useFullAssessment";

interface FunctionalProps {
  ids: Array<string> | null;
  selectedIds: Array<string>;
  assessments: Record<string, FullAssessment> | null;
  error: string | null;
  onChange: (selected: Array<string>) => void;
}

interface StyleProps {
  minWidth?: number;
}

type Props = FunctionalProps & StyleProps;

const ComparisonSelect: FC<Props> = ({ ids, selectedIds, assessments, error, onChange }) => {
  const label = "Compare";
  const handleChange = useCallback(
    (event) => {
      const newValue = event?.target?.value;
      onChange(newValue || []);
    },
    [onChange]
  );

  return (
    <FormControl variant="outlined">
      <InputLabel margin="dense">{label}</InputLabel>
      <Select
        classes={{ select: styles.comparisonSelect }}
        label={label}
        multiple
        value={selectedIds}
        renderValue={(ids: unknown) => (ids instanceof Array && ids?.length ? `${ids.length} selected` : "")}
        onChange={handleChange}
        disabled={ids === null || ids.length === 0 || Boolean(error)}
      >
        {ids && // TODO render loading throbber
          ids.map((id) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={selectedIds.includes(id)} />
              <ListItemText primary={assessments?.[id] ? toAusDate(assessments?.[id].testDate) : ""} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ComparisonSelect;
