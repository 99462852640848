import React, { FC } from "react";
import styles from "./BorderedContainer.module.scss";

interface Props {
  title: string;
  "data-testid"?: string;
}

const BorderedContainer: FC<Props> = (props) => {
  return (
    <div className={styles.container} data-testid={props["data-testid"]}>
      <label>{props.title}</label>
      {props.children}
    </div>
  );
};

export default BorderedContainer;
