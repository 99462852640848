import React, { FC, useContext } from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import logo from "./AIS_Stacked_Blue.png";
import styles from "./MenuBar.module.scss";
import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../auth-wrapper/AuthContext";

export interface MenuBarProps {
  menuItems: Array<{ name: string; to: string }>;
}

const MenuBar: FC<MenuBarProps> = ({ menuItems }) => {
  const { pathname } = useLocation();
  const { email, logout } = useContext(AuthContext);

  return (
    <AppBar className={styles.nav} position="static">
      <Toolbar disableGutters={true}>
        <div className={styles.logoWrapper}>
          <img src={logo} alt="AIS Logo" className={styles.logo} />
        </div>
        {menuItems.map((menuItem) => {
          const active = pathname === menuItem.to;
          return (
            <NavLink className={styles.link} to={menuItem.to} key={menuItem.to}>
              <Button className={clsx(styles.menuItem, { [styles.active]: active })} size="large" color="inherit">
                {menuItem.name}
              </Button>
            </NavLink>
          );
        })}
        <div className={styles.email}>{email}</div>
        {logout && (
          <Button className={styles.menuItem} size="large" color="inherit" onClick={logout}>
            {"Logout"}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
