import React, { FC, useMemo } from "react";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import styles from "../graph-view/GraphView.module.scss";
import { Measure } from "../../utils/api";
import { parseISO } from "date-fns";
import { isDefined } from "../../utils/misc";
import { Lt1Method, Lt2Method, LtMethod } from "./types";
import { FullAssessment } from "../../hooks/useFullAssessment";

const Plot = createPlotlyComponent(Plotly);

const GRID_COLOR = "#dbdbdb";

interface Props {
  controlled: Measure;
  assessments: Array<FullAssessment>;
  lt1Method: Lt1Method;
  lt2Method: Lt2Method;
}

function generateTimeSeries(assessments: Array<FullAssessment>, property: keyof FullAssessment, name: string) {
  const filtered = assessments.filter((a) => isDefined(a[property]));
  const x = filtered.map((a) => parseISO(a.testDate));
  const y = filtered.map((a) => a[property]);
  return {
    name,
    x,
    y,
    type: "scatter",
    mode: "lines+markers",
  } as const;
}

function getLtProperty(method: LtMethod): keyof FullAssessment {
  switch (method) {
    case "Calculated":
      return "lt1Calculated";
    case "Measured":
      return "lt1Measured";
    case "D-Max":
      return "lt2Dmax";
    case "Modified D-Max":
      return "lt2DmaxMod";
    case "4mmol":
      return "lt2At4mmol";
  }
}

const TimelineGraph: FC<Props> = ({ controlled, assessments, lt1Method, lt2Method }) => {
  const lt1Data = useMemo(() => generateTimeSeries(assessments, getLtProperty(lt1Method), "LT1"), [
    assessments,
    lt1Method,
  ]);
  const lt2Data = useMemo(() => generateTimeSeries(assessments, getLtProperty(lt2Method), "LT2"), [
    assessments,
    lt2Method,
  ]);
  const maxData = useMemo(() => generateTimeSeries(assessments, "maxControlled", "Max"), [assessments]);

  return (
    <Plot
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data={[lt1Data, lt2Data, maxData]}
      layout={{
        autosize: true,
        xaxis: {
          gridcolor: GRID_COLOR,
        },
        yaxis: {
          automargin: true,
          hoverformat: ".1f",
          title: {
            text: controlled.units ? `${controlled.label} (${controlled.units})` : controlled.label,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            standoff: 5,
          },
          gridcolor: GRID_COLOR,
          titlefont: {
            size: 16,
            color: "#000033",
          },
        },
        margin: {
          l: 40,
          r: 10,
          b: 40,
          t: 50,
        },
        legend: {
          x: 1,
          y: 1,
          xanchor: "right",
          yanchor: "bottom",
          orientation: "h",
        },
      }}
      config={{
        modeBarButtonsToRemove: ["select2d", "lasso2d", "toggleSpikelines"],
        displaylogo: false,
        responsive: true,
      }}
      useResizeHandler
      style={{ width: "100%", height: "100%", minHeight: "55vh" }}
      className={styles.plotlyContainer}
    />
  );
};

export default TimelineGraph;
