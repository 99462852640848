import React, { FC, useMemo } from "react";
import GraphView from "components/graph-view/GraphView";
import { Measure, Step } from "utils/api";
import { Annotation, PlotData } from "components/graph-view/types";
import { Box } from "@material-ui/core";
import { FullAssessment } from "hooks/useFullAssessment";
import plotStyles from "./plotStyles";
import { toAusDate } from "../../utils/misc";
import Formula from "./Formula";

interface Props {
  assessment: FullAssessment;
  comparisons: Array<FullAssessment>;
  annotations: Annotation[];
  workMeasure: Measure;
  fitMeasure: Measure;
  minHeightVH?: string;
  lt1: number | null;
  lt2: number | null;
  showLegend?: boolean;
}

function compileData(steps: Array<Step>, dependentId: string, controlledId: string) {
  const measurements: number[] = [];
  const controlledValues: number[] = [];
  steps.forEach((step) => {
    if (step[dependentId] && step[controlledId]) {
      measurements.push(Number(step[dependentId]));
      controlledValues.push(Number(step[controlledId]));
    }
  });
  return [measurements, controlledValues];
}

const Graph: FC<Props> = ({
  assessment,
  comparisons,
  fitMeasure,
  annotations,
  workMeasure,
  minHeightVH,
  showLegend,
}) => {
  const testData = useMemo(() => {
    const [measurements, workValues] = compileData(assessment.steps, fitMeasure.id, workMeasure.id);
    const { light, dark, shape } = plotStyles[0];
    return {
      name: `${toAusDate(assessment.testDate)} (this test)`,
      primary: dark,
      secondary: light,
      symbol: shape,
      width: 2,
      size: 9,
      workValues,
      measurements,
      regression: assessment.regressions.get(fitMeasure.id) ?? null,
    };
  }, [assessment, fitMeasure, workMeasure]);

  const additionalData: Array<PlotData> = useMemo(
    () =>
      comparisons.map((a, i) => {
        const [measurements, workValues] = compileData(a.steps, fitMeasure.id, workMeasure.id);
        const { light, dark, shape } = plotStyles[(i + 1) % plotStyles.length];
        return {
          name: `${toAusDate(a.testDate)}`,
          primary: dark,
          secondary: light,
          symbol: shape,
          width: 1,
          size: 8,
          workValues,
          measurements,
          regression: a.regressions.get(fitMeasure.id) ?? null,
        };
      }),
    [comparisons, fitMeasure, workMeasure]
  );

  const allData = useMemo(() => [testData, ...additionalData], [testData, additionalData]);

  return (
    <Box data-testid="assessment-view-graph">
      <GraphView
        plots={allData}
        annotations={annotations}
        workMeasure={workMeasure}
        fitMeasure={fitMeasure}
        minHeightVH={minHeightVH}
        showLegend={showLegend && additionalData.length > 0}
      />
      {testData.regression ? <Formula fit={testData.regression} /> : null}
    </Box>
  );
};

export default React.memo(Graph);
