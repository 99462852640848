import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { ChangeEvent, FC, useRef } from "react";
import { Measure } from "utils/api";
import { getMeasureLabel } from "utils/misc";
import styles from "./MeasureItem.module.scss";
import { useDrag, useDrop, DropTargetMonitor, DragSourceMonitor } from "react-dnd";

interface Props {
  measure: Measure;
  isChecked: boolean;
  handleChange?(event: ChangeEvent<HTMLInputElement>): void;
  index: number;
  moveMeasure: (from: number, to: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const MeasureItem: FC<Props> = ({ measure, isChecked, handleChange, index, moveMeasure }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    item: { type: "Measure", index: index, measureId: measure.id },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "Measure",
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveMeasure(dragIndex, hoverIndex);

      // taken from example...
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  if (isChecked === true) {
    drag(drop(ref));
  } else {
    drop(ref);
  }

  return (
    <div ref={ref} className={styles.container} style={{ cursor: isChecked ? "move" : "", opacity }}>
      <FormControlLabel
        key={measure.id}
        className={styles.measuresLabel}
        control={<Checkbox onChange={handleChange} checked={isChecked} name={measure.id} />}
        label={getMeasureLabel(measure)}
      />
    </div>
  );
};

export default MeasureItem;
