import React, { FC, useCallback, useMemo } from "react";
import DataTable, { Column } from "../data-table/DataTable";
import { Step } from "../../utils/api";
import { useMeasures } from "../../hooks/useMeasures";
import { format } from "../../hooks/useFormatter";

interface Props {
  columns: Column[];
  rows: Step[];
  steps: Step[];
}

const LtTable: FC<Props> = ({ columns, rows, steps }) => {
  const [measures] = useMeasures();

  const maxes = useMemo(
    () =>
      steps.reduce<Record<string, number>>((acc, step) => {
        Object.entries(step).forEach(([id, value]) => {
          const numericalValue = value ? parseFloat(value) : NaN;
          if (!Number.isNaN(numericalValue)) {
            if (acc[id]) {
              acc[id] = Math.max(numericalValue, acc[id]);
            } else {
              acc[id] = numericalValue;
            }
          }
        });
        return acc;
      }, {}),
    [steps]
  );

  const withPercentageMax = useCallback(
    (id?: string, value?: string | null) => {
      const measure = measures && id ? measures.get(id) : undefined;
      const max: number | undefined = id ? maxes[id] : undefined;
      const formattedValue = format(measure, value);
      const numericalValue = value ? parseFloat(value) : NaN;

      if (formattedValue && measure?.showPercentage && max && !Number.isNaN(numericalValue)) {
        const percentMax = ((numericalValue / max) * 100).toFixed();
        return `${formattedValue} (${percentMax}%)`;
      }
      return formattedValue;
    },
    [measures]
  );

  return (
    <DataTable
      columns={columns}
      rows={rows}
      pasteEnabled={false}
      sortEnabled={false}
      showHeaders={false}
      formatter={withPercentageMax}
    />
  );
};

export default LtTable;
