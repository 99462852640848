import { Measure, Step } from "utils/api";
import styles from "./StepsEditor.module.scss";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import React, { MouseEventHandler, useState } from "react";
import Remove from "@material-ui/icons/Remove";

interface ColumnRemoverProps {
  measure: Measure;
  steps: Array<Step>;
  onRemoved: (measureId: string) => void;
}

export const ColumnRemover: React.FunctionComponent<ColumnRemoverProps> = ({ measure, steps, onRemoved }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleButtonClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    const hasData = steps.some((step) => step[measure.id]);
    if (hasData) {
      setAlertOpen(true);
    } else {
      onRemoved(measure.id);
    }
  };

  const handleDialogClose = () => {
    setAlertOpen(false);
  };

  const handleAgreement = () => {
    setAlertOpen(false);
    onRemoved(measure.id);
  };

  return (
    <div>
      <Button title="Remove Column" className={styles.removeButton} onClick={handleButtonClick}>
        <Remove color="error" />
      </Button>
      <Dialog
        open={alertOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Remove ${measure.label} column?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`This column has ${measure.label} data in it. Are you sure you want to remove this data?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAgreement} color="primary" variant="outlined">
            Remove Column
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
