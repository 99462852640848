import React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import styles from "./Throbber.module.scss";

interface ThrobberProps {
  message?: string;
}

// TODO move to component library puhlease
const Throbber: React.FunctionComponent<ThrobberProps> = ({ message }) => {
  return (
    <Box className={styles.throbber}>
      <CircularProgress />
      <h4>{message}</h4>
    </Box>
  );
};

export default Throbber;
