import React, { FC, useState } from "react";
import { HelpOutline } from "@material-ui/icons";
import { Modal, IconButton, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import styles from "./LTInfo.module.scss";

const LTInfo: FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <HelpOutline className={styles.infoIcon} />
      </IconButton>
      <Modal className={styles.modal} open={open} onClose={() => setOpen(false)}>
        <Paper className={styles.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.headerCell}>Method</TableCell>
                <TableCell className={styles.headerCell}>Calculation Used</TableCell>
                <TableCell className={styles.headerCell}>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={styles.methodCell}>LT1 Calculated</TableCell>
                <TableCell>
                  The calculated lactate and corresponding intensity at the point on the third order polynomial that
                  equates to a lactate value 0.4 mmol/L above the lowest measured value.
                </TableCell>
                <TableCell>
                  This is an interpolated technique to calculate the probable workload that this would have occurred at
                  and does not necessarily correspond to an actual workload completed in the test. The lowest measured
                  lactate value may not necessarily be the first measured value in a test, sometimes the second measure
                  is lower than the first. For example, if the lowest measured lactate is 0.8mmol/L then LT1calculated =
                  0.8 + 0.4 = 1.2mmol/L and corresponding intensity.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.methodCell}>LT1 Measured</TableCell>
                <TableCell>
                  The measured lactate and corresponding intensity from the workload immediately preceding a ≥0.4 mmol/L
                  rise in lactate above the lowest measured value.
                </TableCell>
                <TableCell>
                  This is an actual measured lactate value from a workload / intensity completed in the test. You are
                  choosing an actual workload (step) and its corresponding measured lactate value (as opposed to a
                  calculated value). For example, if the lowest measured lactate value is 0.8mmol/L then you look for
                  the measured lactate value that is closest (but less than) 1.2mmol/L. Reference: Physiological Tests
                  for Elite Athletes 2nd Ed (p80).
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.methodCell}>LT2 D-Max</TableCell>
                <TableCell>
                  The lactate and corresponding intensity at the point on the 3rd order polynomial that yields the
                  maximal perpendicular distance to the straight line formed by the first and final measured lactate and
                  corresponding intensity values.
                </TableCell>
                <TableCell>
                  This method is may be used by sports where there are long rest intervals in between steps in the test
                  and for interpretation purposes.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.methodCell}>LT2 Modified D-Max</TableCell>
                <TableCell>
                  The lactate and corresponding intensity at the point on the 3rd order polynomial that yields the
                  maximal perpendicular distance to the straight line formed by the point preceding an increase of
                  lactate concentration ≥0.4mmol/L above the lowest measured value (i.e. LT1 measured) and the final
                  lactate and corresponding intensity values .
                </TableCell>
                <TableCell>
                  As defined, LT1 (measured) is always the lower anchor point for the modified D-Max calculation method
                  regardless whether you have chosen LT1 (calculated) or another method to determine LT1.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.methodCell}>LT2 4mmol</TableCell>
                <TableCell>The intensity associated with 4 mmol/L as determined by the 3rd order polynomial.</TableCell>
                <TableCell>This method may be used for sports that complete submaximal step tests.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Modal>
    </>
  );
};

export default LTInfo;
