import { getTesters, postTester, Tester, Unsaved } from "utils/api";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { useCancelToken } from "./useCancelToken";
import { showNotification } from "utils/notifications";

export interface FieldErrors {
  firstName?: string;
  surname?: string;
  dateOfBirth?: string;
}

export function useTesters() {
  const [testers, setTesters] = useState<Array<Tester> | null>(null);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [error, setError] = useState<string | null>(null);

  const cancelToken = useCancelToken();

  useEffect(() => {
    getTesters()
      .then(setTesters)
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err.toString());
        }
      });
  }, [cancelToken]);

  const addTester = useCallback(
    (newTester: Unsaved<Tester>) => {
      setLoading(true);

      return postTester(newTester, cancelToken)
        .then((createdTester) => {
          setLoading(false);
          setFieldErrors({});

          if (testers) {
            setTesters(_.sortBy([...testers, createdTester], (tester) => tester.surname.toLowerCase()));
            return createdTester;
          } else {
            throw new Error("Stopped an attempt to add an athlete before existing testers have been fetched.");
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setLoading(false);
            if (err.response?.status === 400 && err.response?.data) {
              setFieldErrors({ ...err.response.data });
            } else {
              showNotification({
                message: "Failed to save tester, please reload the page and try again.",
                severity: "error",
              });
            }
          }
        });
    },
    [testers, setLoading, setFieldErrors, setTesters]
  );

  return { testers, loading, error, fieldErrors, addTester };
}
