interface PlotStyle {
  light: string;
  dark: string;
  shape: string;
}

const styles: Array<PlotStyle> = [
  {
    light: "#aad0e4",
    dark: "#2d7fb7",
    shape: "circle",
  },
  {
    light: "#b4e08e",
    dark: "#41a439",
    shape: "square",
  },
  {
    light: "#fb9e9d",
    dark: "#e42b2a",
    shape: "diamond",
  },
  {
    light: "#fdc276",
    dark: "#fe851b",
    shape: "cross",
  },
  {
    light: "#ccb5d6",
    dark: "#70469e",
    shape: "x",
  },
  {
    light: "#fdfe9d",
    dark: "#b46033",
    shape: "star-triangle-down",
  },
  {
    light: "#aad0e4",
    dark: "#2d7fb7",
    shape: "square",
  },
  {
    light: "#b4e08e",
    dark: "#41a439",
    shape: "diamond",
  },
  {
    light: "#fb9e9d",
    dark: "#e42b2a",
    shape: "cross",
  },
  {
    light: "#fdc276",
    dark: "#fe851b",
    shape: "x",
  },
  {
    light: "#ccb5d6",
    dark: "#70469e",
    shape: "star-triangle-down",
  },
  {
    light: "#fdfe9d",
    dark: "#b46033",
    shape: "circle",
  },
];

export default styles;
