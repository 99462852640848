import { Subject } from "rxjs";

export interface Notification {
  severity: "info" | "warning" | "error" | "success";
  message: string;
}

export const notifications$ = new Subject<Notification>();

export const showNotification = (nextNotification: Notification): void => {
  notifications$.next(nextNotification);
};
