import { Column } from "material-table";

// we have to use the object type here because it's what material-table uses
// eslint-disable-next-line @typescript-eslint/ban-types
export function buildOrderingParam<T extends object>(
  orderBy: Column<T>,
  orderDirection: "asc" | "desc",
  defaultOrdering = "-testDate"
): string | undefined {
  if (!orderBy?.field) {
    return undefined;
  }
  const orderPrefix = orderDirection === "desc" ? "-" : "";
  const orderField = orderBy.field;
  return `${orderPrefix}${orderField},${defaultOrdering}`;
}
