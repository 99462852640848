import useLogging from "hooks/useLogger";
import { useCallback, useEffect, useReducer } from "react";
import { eject, getAuthClient } from "../../utils/auth";
import authReducer, { AuthState, initialState } from "./authReducer";

interface AuthHookState extends AuthState {
  logout: () => void;
}

export function useAuth(): AuthHookState {
  const [{ authenticated, authorised, email, loading, error }, dispatch] = useReducer(authReducer, initialState);
  const logger = useLogging();

  useEffect(() => {
    const initAuth = async () => {
      try {
        const authClient = await getAuthClient();
        const authenticated = await authClient.isAuthenticated();
        let authorised = false;
        if (authenticated) {
          const token = await authClient.getTokenSilently();
          const claims = JSON.parse(atob(token.split(".")[1]));
          authorised = claims["permissions"]?.includes("access:adapt-data"); // TODO config value
        }
        dispatch({
          type: "authenticated",
          payload: {
            authenticated,
            authorised,
          },
        });
      } catch (err) {
        if (err?.error === "consent_required") {
          eject();
        } else {
          logger.error("initAuth failed: ", err);
        }
      }
    };
    initAuth();
  }, []);

  useEffect(() => {
    if (!(authenticated || loading || error)) {
      const triggerLogin = async () => {
        try {
          dispatch({ type: "loading" });
          const authClient = await getAuthClient();
          await authClient.loginWithRedirect({
            appState: {
              targetUrl: window.location.pathname,
            },
          });
        } catch (err) {
          logger.error("triggerLogin failed: ", err);
        }
      };
      triggerLogin();
    }
  }, [authenticated, loading, error]);

  useEffect(() => {
    if (authenticated) {
      const getEmail = async () => {
        try {
          const authClient = await getAuthClient();
          const user = await authClient.getUser();
          dispatch({
            type: "email",
            payload: {
              email: user.email,
            },
          });
        } catch (err) {
          logger.error("getEmail failed: ", err);
        }
      };
      getEmail();
    }
  }, [authenticated]);

  const logout = useCallback(async () => {
    try {
      dispatch({ type: "loading" });
      const authClient = await getAuthClient();
      authClient.logout({
        returnTo: `${window.location.origin}/`,
      });
    } catch (err) {
      logger.error("logout failed: ", err);
    }
  }, []);

  return {
    authenticated,
    authorised,
    email,
    loading,
    error,
    logout,
  };
}
