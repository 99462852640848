import RollbarContext from "context/RollbarContext";
import React, { Component, ErrorInfo } from "react";
import ErrorPage from "./ErrorPage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType = RollbarContext;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    this.context?.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
