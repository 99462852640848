// Miscellaneous utilities. All can perhaps be moved somewhere that makes more sense...

import { IdMap } from "../shared-types";
import { format, parseISO } from "date-fns";
import { ApiObject, Measure } from "./api";

export function convertToIdMap<T extends ApiObject>(arr: Array<T>): IdMap<T> {
  return new Map<string, T>(arr.map((item) => [item.id, item]));
}

export function isDefined<T>(thing: T | null | undefined): thing is T {
  return thing !== undefined && thing !== null;
}

export function toAusDate(date: string): string {
  try {
    return format(parseISO(date), "dd-MM-yyyy");
  } catch {
    return date; // returns original string if conversion fails
  }
}

export function getMeasureLabel(measure?: Measure): string {
  if (!measure) {
    return "";
  }

  return measure.label + (measure.units ? ` (${measure.units})` : "");
}
