import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AssessmentView from "./components/assessment-view/AssessmentView";
import ListView from "./components/assessment-list/AssessmentList";
import AssessmentEdit from "./components/assessment-edit/AssessmentEdit";
import MenuBar from "./components/menu-bar/MenuBar";
import { MeasuresProvider } from "./context/MeasuresContext";
import NotificationsView from "./components/notifications-view/NotificationsView";
import { AuthProvider } from "./components/auth-wrapper/AuthContext";
import RollbarContext from "context/RollbarContext";
import Rollbar from "rollbar";
import { createMuiTheme, CssBaseline, MuiThemeProvider, StylesProvider } from "@material-ui/core";
import ProtocolListView from "components/protocol-list/ProtocolListView";
import ErrorBoundary from "components/error-page/ErrorBoundary";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000033",
    },
    secondary: {
      main: "#ffc709",
    },
  },
});

const menuItems = [
  { name: "All tests", to: "/" },
  { name: "Add new test", to: "/assessments/edit/" },
  { name: "Manage protocols", to: "/protocols/edit/" },
];

// TODO: Temp app state for tests
// TODO: Temp app state for protocols

export default function App() {
  const rollbar = new Rollbar({
    enabled: process.env.NODE_ENV === "production",
    accessToken: "d71dabc328a74a67900b09e653b83f34",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: "production",
    },
  });

  return (
    <div className="App">
      <RollbarContext.Provider value={rollbar}>
        <CssBaseline />
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <AuthProvider>
              <MeasuresProvider>
                <Router>
                  <MenuBar menuItems={menuItems} />
                  <Switch>
                    <Route path={`/assessments/view/:assessmentId?`}>
                      <AssessmentView />
                    </Route>
                    <Route path={`/assessments/edit/:assessmentId?`}>
                      <ErrorBoundary>
                        <AssessmentEdit />
                      </ErrorBoundary>
                    </Route>
                    <Route path={`/protocols/edit`}>
                      <ProtocolListView />
                    </Route>
                    <Route path={`/`}>
                      <ListView />
                    </Route>
                  </Switch>
                </Router>
                <NotificationsView />
              </MeasuresProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </RollbarContext.Provider>
    </div>
  );
}
