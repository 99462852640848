import React, { FC } from "react";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import styles from "./GraphView.module.scss";
import { PlotData, Annotation } from "./types";
import { Measure } from "../../utils/api";
import { formatWithUnits } from "../../hooks/useFormatter";

const Plot = createPlotlyComponent(Plotly);

const GRID_COLOR = "#dbdbdb";

interface Props {
  plots: PlotData[];
  annotations?: Array<Annotation>;
  workMeasure: Measure;
  fitMeasure: Measure;
  minHeightVH?: string;
  showLegend?: boolean;
  annotationYPosition?: number;
}

const buildHovers = (xs: number[], ys: number[], workMeasure: Measure, fitMeasure: Measure) => {
  return xs.map((x, i) => {
    // sneaky strings seem to be getting through here...
    return `${formatWithUnits(workMeasure, x.toString())}<br />${formatWithUnits(fitMeasure, ys[i].toString())}`;
  });
};

const toPlotData = (
  { name, primary, secondary, symbol, width, size, workValues, measurements, regression }: PlotData,
  workMeasure: Measure,
  fitMeasure: Measure
) => {
  const minX = Math.min(...workValues);
  const maxX = Math.max(...workValues);

  const measurementData = {
    name,
    x: workValues,
    y: measurements,
    hovertext: buildHovers(workValues, measurements, workMeasure, fitMeasure),
    hoverinfo: "text",
    type: "scatter",
    mode: "markers",
    marker: {
      color: secondary,
      symbol,
      size,
      line: {
        color: primary, // TODO same as background
        width,
      },
    },
    legendgroup: name,
  } as const;

  let fitData = null;

  if (regression !== null) {
    // We want to have a point for every 0.1 of work data as that is our precision boundary.
    // Thus we times the difference of the min and max by 10 and divide when creating the point.
    const numberOfPoints = (maxX - minX) * 10 + 1;
    const fitXs = Array.from({ length: numberOfPoints }, (_, i) => minX + i / 10);

    const fitYs = fitXs.map((x) => regression.getAt(x));

    fitData = {
      name: "Regression",
      x: fitXs,
      y: fitYs,
      hovertext: buildHovers(fitXs, fitYs, workMeasure, fitMeasure),
      hoverinfo: "text",
      type: "scatter",
      mode: "lines",
      line: {
        width,
        color: primary,
      },
      legendgroup: name,
      showlegend: false,
    } as const;
  }

  return fitData ? ([fitData, measurementData] as const) : ([measurementData] as const);
};

function toShapeConfig({ value, color }: Annotation) {
  return [
    {
      type: "line",
      x0: value,
      x1: value,
      yref: "paper",
      y0: 0,
      y1: 1,
      line: {
        width: 1,
        color,
        dash: "dash",
      },
    },
  ] as const;
}

function toAnnotationConfig({ name, value, hoverText, color }: Annotation) {
  return [
    {
      text: `${name}`,
      font: {
        size: 16,
        color,
      },
      showarrow: false,
      x: value,
      y: 0.5,
      yanchor: "bottom",
      yref: "paper",
      hovertext: hoverText,
      bgcolor: "rgba(255, 255, 255, 0.9)",
      borderpad: 4,
    },
  ] as const;
}

const GraphView: FC<Props> = ({ plots, annotations, workMeasure, fitMeasure, minHeightVH, showLegend }) => (
  <Plot
    data={plots.flatMap((plot: PlotData) => toPlotData(plot, workMeasure, fitMeasure))}
    layout={{
      autosize: true,
      showlegend: showLegend ?? false,
      xaxis: {
        title: workMeasure.units ? `${workMeasure.label} (${workMeasure.units})` : workMeasure.label,
        gridcolor: GRID_COLOR,
      },
      yaxis: {
        automargin: true,
        title: {
          text: fitMeasure.units ? `${fitMeasure.label} (${fitMeasure.units})` : fitMeasure.label,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          standoff: 5,
        },
        gridcolor: GRID_COLOR,
        titlefont: {
          size: 16,
          color: "#000033",
        },
      },
      margin: {
        l: 40,
        r: 10,
        b: 40,
        t: 20,
      },
      legend: showLegend
        ? {
            x: 0,
            y: 1,
            xanchor: "left",
            yanchor: "bottom",
            orientation: "h",
          }
        : undefined,
      shapes: annotations && annotations.flatMap(toShapeConfig),
      annotations: annotations && annotations.flatMap((a) => toAnnotationConfig(a)),
    }}
    config={{
      modeBarButtonsToRemove: ["select2d", "lasso2d", "toggleSpikelines"],
      displaylogo: false,
      responsive: true,
    }}
    useResizeHandler
    style={{ width: "100%", height: "100%", minHeight: minHeightVH || "55vh" }}
    className={styles.plotlyContainer}
  />
);

export default GraphView;
