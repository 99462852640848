import React, { FC } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { toAusDate } from "utils/misc";
import styles from "./TestDetails.module.scss";
import { FullAssessment } from "hooks/useFullAssessment";
import { useHistory } from "react-router-dom";
import { deleteAssessment } from "utils/api";
import { Notification, showNotification } from "utils/notifications";
import useLogging from "hooks/useLogger";
import { useAuth } from "components/auth-wrapper/useAuth";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

const deleteSuccessNotification: Notification = {
  severity: "info",
  message: "The assessment was successfully deleted.",
};

const deleteFailedNotification: Notification = {
  severity: "error",
  message: "Something went wrong and we were unable to delete the assessment. Please refresh and try again.",
};

const LabelValue: FC<{ label: string; value: string | null | number }> = ({ label, value }) => {
  if (value === null) {
    return null;
  }

  return (
    <div className={styles.labelValue}>
      <Typography color="primary" className={styles.title} display="inline">{`${label}:`}</Typography>
      <Typography display="inline">{typeof value === "number" ? value.toFixed(1) : value}</Typography>
    </div>
  );
};

interface Props {
  assessment: FullAssessment;
}

const TestDetails: FC<Props> = ({ assessment }) => {
  const history = useHistory();
  const { email } = useAuth();
  const logger = useLogging();

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete the assessment? This action cannot be undone.")) {
      deleteAssessment(assessment.id)
        .then((res) => {
          if (res.status === 204) {
            history.push("/");
            showNotification(deleteSuccessNotification);
            logger.info(`Assessment ${assessment.id} deleted by ${email}.`);
          } else {
            showNotification(deleteFailedNotification);
            logger.error(
              `Failed to delete assessment ${assessment.id}. User: ${email}. Response: ${res.status}, ${res.statusText}.`
            );
          }
        })
        .catch((err: Error) => {
          showNotification(deleteFailedNotification);
          logger.error(`Failed to delete assessment ${assessment.id}. User: ${email}`, err);
        });
    }
  };

  return (
    assessment && (
      <div>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Typography
            className={styles.name}
            variant="h3"
          >{`${assessment.athlete.firstName} ${assessment.athlete.surname}`}</Typography>
          <div>
            <Button
              color="primary"
              className={styles.editButton}
              onClick={() => history.push(`/assessments/edit/${assessment.id}`)}
            >
              <Edit className={styles.buttonIcon} fontSize="small" />
              {"Edit"}
            </Button>
            <Button color="primary" className={styles.deleteButton} onClick={onDelete}>
              <Delete className={styles.buttonIcon} fontSize="small" />
              {"Delete"}
            </Button>
          </div>
        </Grid>
        <Grid container direction="row">
          <LabelValue label="Tested" value={toAusDate(assessment.testDate)} />
          <LabelValue label="Protocol" value={assessment.protocol.name} />
          <LabelValue label="Temperature (°C)" value={assessment.temperature} />
          <LabelValue label="Rel.Humidity (%)" value={assessment.humidity} />
          <LabelValue label="Pressure (mmHg)" value={assessment.pressure} />
          <LabelValue label="Athlete Height (cm)" value={assessment.athleteHeight} />
          <LabelValue label="Athlete Weight (kg)" value={assessment.athleteWeight} />
        </Grid>
      </div>
    )
  );
};

export default TestDetails;
