import React, { useEffect, useMemo, useState, createContext } from "react";
import { IdMap } from "shared-types";
import { getMeasures, Measure } from "utils/api";
import axios from "axios";
import { useCancelToken } from "hooks/useCancelToken";
import { convertToIdMap } from "utils/misc";

export const MeasuresContext = createContext<[IdMap<Measure> | null, string | null]>([null, null]);

export const MeasuresProvider: React.FunctionComponent = ({ children }) => {
  const [fetchedMeasures, setFetchedMeasures] = useState<Array<Measure> | null>(null);
  const [error, setError] = useState<string | null>(null);

  const cancelToken = useCancelToken();

  useEffect(() => {
    getMeasures(cancelToken)
      .then(setFetchedMeasures)
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err.toString());
        }
      });
  }, [cancelToken]);

  const measures = useMemo(() => fetchedMeasures && convertToIdMap(fetchedMeasures), [fetchedMeasures]);

  return <MeasuresContext.Provider value={[measures, error]}>{children}</MeasuresContext.Provider>;
};
