import { getProtocols, ApiProtocol, postProtocol, ApiProtocolError, putProtocol, Unsaved } from "../utils/api";
import { useCallback, useEffect, useState } from "react";
import axios, { CancelToken } from "axios";
import { showNotification } from "utils/notifications";
import _ from "lodash";

interface UseProtocolsResult {
  /** A sorted array of ApiProtocol's. They are sorted by name.  */
  protocols: ApiProtocol[] | null;
  /** A boolean indicator for when the results of a ReST call are being waiting on. */
  loading: boolean;
  /** An object of protocol id's to strings that represent an error returned while trying to save or add a protocol. Used to show errors in forms.  */
  fieldErrors: ApiProtocolError;
  /** A string message from a general API error. */
  error: string | null;
  /** A function to add new protocols in the backend. */
  addProtocol(protocol: Unsaved<ApiProtocol>): Promise<ApiProtocol>;
  /** A function to update existing protocols in the backend. */
  updateProtocol(protocol: ApiProtocol): Promise<ApiProtocol>;
}

/** A hook for interacting with test protocols. */
export function useProtocols(): UseProtocolsResult {
  const [protocols, setProtocols] = useState<Array<ApiProtocol> | null>(null);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ApiProtocolError>({});
  const [error, setError] = useState<string | null>(null);

  const [cancelToken] = useState<CancelToken>(axios.CancelToken.source().token);

  useEffect(() => {
    setLoading(true);
    getProtocols(cancelToken)
      .then((protocols) => {
        setLoading(false);
        setProtocols(protocols);
      })
      .catch((err) => {
        setLoading(false);
        if (!axios.isCancel(err)) {
          setError(err.toString());
        }
      });
  }, [cancelToken]);

  const addProtocolToState = useCallback(
    (newProtocol: ApiProtocol) => {
      if (protocols) {
        const filteredProtocols = protocols.filter((protocol) => {
          return protocol.id !== newProtocol.id;
        });
        setProtocols(_.sortBy([...filteredProtocols, newProtocol], (proto) => proto.name.toLowerCase()));
      } else {
        throw new Error("Stopped an attempt to add a protocol before existing protocol have been fetched.");
      }
    },
    [protocols]
  );

  const onAddUpdateSuccess = useCallback(
    (savedProtocol) => {
      setLoading(false);
      setFieldErrors({});
      addProtocolToState(savedProtocol);

      showNotification({
        message: "Protocol saved successfully.",
        severity: "success",
      });

      return savedProtocol;
    },
    [setLoading, setFieldErrors, addProtocolToState]
  );

  const onAddUpdateError = useCallback((err) => {
    if (!axios.isCancel(err)) {
      setLoading(false);
      if (err.response?.status === 400 && err.response?.data) {
        setFieldErrors(err.response.data);
        showNotification({
          message: "Failed to save Protocol, fix errors and try again.",
          severity: "error",
        });
      } else {
        showNotification({
          message: "Failed to save Protocol, please reload the page and try again.",
          severity: "error",
        });
      }
    }
  }, []);

  const addProtocol = useCallback(
    (protocol: Unsaved<ApiProtocol>) => {
      setLoading(true);
      return postProtocol(protocol, cancelToken).then(onAddUpdateSuccess).catch(onAddUpdateError);
    },
    [cancelToken, onAddUpdateSuccess, onAddUpdateError]
  );

  const updateProtocol = useCallback(
    (protocol: ApiProtocol) => {
      setLoading(true);
      return putProtocol(protocol, cancelToken).then(onAddUpdateSuccess).catch(onAddUpdateError);
    },
    [cancelToken, onAddUpdateSuccess, onAddUpdateError]
  );

  return { protocols, loading, fieldErrors, error, addProtocol, updateProtocol };
}
