import { IconButton, MenuItem, Menu } from "@material-ui/core";
import React, { useState } from "react";
import { Measure } from "utils/api";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import styles from "./StepsEditor.module.scss";

interface ColumnAdderProps {
  options: Array<Measure>;
  onAdded: (measureId: string) => void;
}

export const ColumnAdder: React.FunctionComponent<ColumnAdderProps> = ({ options, onAdded }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelected = (measureId: string) => {
    onAdded(measureId);
    handleClose();
  };

  const menuItems = options.map((measure) => (
    <MenuItem key={measure.id} onClick={() => handleItemSelected(measure.id)}>
      {measure.label + (measure.units ? ` (${measure.units})` : "")}
    </MenuItem>
  ));

  return (
    <React.Fragment>
      <IconButton
        data-testid="assessment-edit-add-column"
        title={"Add Column"}
        aria-controls="add-column-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
        classes={{
          root: styles.addButton,
        }}
        disabled={!options.length}
      >
        <AddCircleOutline />
      </IconButton>
      <Menu id="add-column-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems}
      </Menu>
    </React.Fragment>
  );
};
