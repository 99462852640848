import { PolyFit } from "models/PolyFit";
import React, { FC, useMemo, Fragment } from "react";
import styles from "./Formula.module.scss";

interface Props {
  fit: PolyFit;
}

interface ExponentProps {
  exp: number;
}

const Exponent: FC<ExponentProps> = ({ exp }) => {
  switch (exp) {
    case 0:
      return null;
    case 1:
      return <Fragment>x</Fragment>;
    default:
      return (
        <Fragment>
          x<sup>{exp}</sup>
        </Fragment>
      );
  }
};

const Formula: FC<Props> = ({ fit: { coefficients, rSquared } }) => {
  const formula = useMemo(() => {
    const terms = coefficients
      .map((coefficient, i) => [coefficient, <Exponent key={i} exp={i} />])
      .filter(([coef]) => coef !== 0)
      .reverse();

    const [firstTerm, ...rest] = terms;
    const firstTermString = (
      <Fragment>
        {firstTerm[0]}
        {firstTerm[1]}
      </Fragment>
    );

    const rhs = rest.reduce((equation, [coefficient, exponentText]) => {
      if (coefficient < 0) {
        return (
          <Fragment>
            {equation} - {-coefficient}
            {exponentText}
          </Fragment>
        );
      }
      return (
        <Fragment>
          {equation} + {coefficient}
          {exponentText}
        </Fragment>
      );
    }, firstTermString);

    return <Fragment>y = {rhs}</Fragment>;
  }, [coefficients]);

  return (
    <div className={styles.container} data-testid="regression-formula">
      <div>{formula}</div>
      <div className={styles.rSquared}>
        R<sup>2</sup> = {rSquared}
      </div>
    </div>
  );
};

export default Formula;
